import { useState } from "react";
import { useAppDispatch } from "../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from "../../components/button";
import FormInput from "../../components/form-input";

import { updatePasswordAction } from "../../redux/user/user-slice";

import { passwordFormValidation } from "../../utils/functions";

type Props = {
  onSuccess: () => void;
};

type FormInputs = {
  old_password: string;
  password: string;
};

const ChangePassword = ({ onSuccess }: Props) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitFormOnSubmit: SubmitHandler<FormInputs> = async (data) => {
    setIsSubmitting(true);

    await dispatch(
      updatePasswordAction({
        ...data,
        password_confirmation: data?.password,
      })
    ).then((res) => {
      if (res === true) {
        reset();
        onSuccess();
      }
    });
    setIsSubmitting(false);
  };

  return (
    <div className="change_password_container">
      <form className="form" onSubmit={handleSubmit(submitFormOnSubmit)}>
        <p>Change your password to a new one</p>
        <FormInput
          label="CURRENT PASSWORD"
          type="password"
          readOnly={isSubmitting}
          errorMessage={errors?.old_password?.message}
          inputRef={{
            ...register("old_password", passwordFormValidation(true)),
          }}
        />
        <FormInput
          label="NEW PASSWORD"
          type="password"
          readOnly={isSubmitting}
          errorMessage={errors?.password?.message}
          inputRef={{
            ...register("password", passwordFormValidation(true)),
          }}
        />

        <div className="action">
          <Button
            text="Save Changes"
            type="submit"
            loading={isSubmitting}
            disabled={!isValid}
          />
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
