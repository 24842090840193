import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { Modal } from "reactstrap";
import { useForm, SubmitHandler } from "react-hook-form";

import SectionHeader from "../section-header";
import Button from "../button";
import FormInput from "../form-input";
import FormSelect from "../form-select";
import Loader from "../loader";
import EnterPin from "../enter-pin";

import { sendOtpAction } from "../../redux/user/user-slice";

import {
  postAirtimePurchaseAction,
  postDataPurchaseAction,
  getDataPlansAction,
} from "../../redux/home/home-slice";

import {
  numberFormValidation,
  basicFormValidation,
} from "../../utils/functions";

type Props = {
  isOpen: boolean;
  close: any;
  onSuccess: any;
};

type FormInputs = {
  type?: string;
  network?: string;
  amount?: number;
  dataBundle?: string;
  phoneNumber?: number;
  saveBeneficiary: boolean;
};

const AirtimeDataModal = ({ isOpen, close, onSuccess }: Props) => {
  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();

  const { user } = useSelector((state: any) => state.userSlice);
  const { networkProviders } = useSelector((state: any) => state.homeSlice);

  const [step, setStep] = useState(1);
  const [dataPlans, setDataPlans] = useState<any | null>(null);
  const [pin, setPin] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [purchaseData, setPurchaseData] = useState<any | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const watchType = watch("type", "");
  const watchNetwork = watch("network", "");
  const watchPhoneNumber = watch("phoneNumber", 0);

  useEffect(() => {
    if (watchNetwork) {
      dispatch(getDataPlansAction({ provider: watchNetwork })).then((res) =>
        setDataPlans(res)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchNetwork]);

  const handlePinChange = (value: string) => {
    setPin(value);
  };
  useEffect(() => {
    if (otp?.length === 4 && pin?.length === 4) {
      let timer = setInterval(function () {
        clearInterval(timer);

        makeTopUpRequest();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp, pin]);

  const makeTopUpRequest = async () => {
    setIsSubmitting(true);

    const funcToUse =
      watchType === "airtime"
        ? postAirtimePurchaseAction
        : postDataPurchaseAction;

    await dispatch(
      funcToUse({
        ...purchaseData,
        otp: otp,
        pin: pin,
      })
    ).then((res) => {
      if (res === true) {
        resetFormValues();
        onSuccess();
      }
    });
    setIsSubmitting(false);
  };

  const saveFormInfo: SubmitHandler<FormInputs> = (data) => {
    const payload = {
      phone: data?.phoneNumber,
      account_number: user?.active_account?.account_number,
      data_plan_id: data?.dataBundle,
      provider: data?.network,
      amount: data?.amount,
    };
    console.log(payload, "payload");

    if (watchType === "airtime") {
      delete payload.data_plan_id;
    } else {
      delete payload.amount;
      delete payload.provider;
    }

    dispatch(sendOtpAction({ email: user?.email }));

    setPurchaseData(payload);
    setStep(2);
  };

  const resetFormValues = () => {
    reset();
    setStep(1);
    setPin("");
    setOtp("");
    setPurchaseData(null);
    setDataPlans(null);
  };

  const typeOptions = [
    { label: "Airtime", value: "airtime" },
    { label: "Data", value: "data" },
  ];

  const networkOptions = networkProviders?.map((x: string) => ({
    label: x,
    value: x,
  }));

  const dataBundleOptions = dataPlans?.map((x: any) => ({
    label: x?.formatted_name,
    value: x?.id,
  }));

  return (
    <Modal className="send_money_modal" isOpen={isOpen}>
      <SectionHeader
        headText="Airtime & Data"
        close={() => {
          close();
          resetFormValues();
        }}
      />

      <div className="body no_padding">
        {step === 1 && (
          <form className="form" onSubmit={handleSubmit(saveFormInfo)}>
            <div className="form_inner">
              <FormSelect
                name="type"
                label="TYPE"
                type="default"
                defaultValue=""
                errorMessage={errors?.type?.message}
                selectOptions={typeOptions}
                selectRef={{
                  ...register("type", basicFormValidation(true)),
                }}
              />

              <FormSelect
                name="network"
                label="NETWORK"
                type="default"
                defaultValue=""
                errorMessage={errors?.network?.message}
                selectOptions={networkOptions}
                selectRef={{
                  ...register("network", basicFormValidation(true)),
                }}
              />

              {watchType !== "data" ? (
                <FormInput
                  label="AMOUNT"
                  type="number"
                  readOnly={isSubmitting}
                  errorMessage={errors?.amount?.message}
                  inputRef={{
                    ...register("amount", numberFormValidation(true, 2, 5)),
                  }}
                />
              ) : (
                <FormSelect
                  name="dataBundle"
                  label="DATA BUNDLE"
                  type="default"
                  defaultValue=""
                  errorMessage={errors?.dataBundle?.message}
                  selectOptions={dataBundleOptions}
                  selectRef={{
                    ...register("dataBundle", basicFormValidation(true)),
                  }}
                />
              )}

              <FormInput
                label="PHONE NUMBER"
                type="number"
                readOnly={isSubmitting}
                errorMessage={errors?.phoneNumber?.message}
                inputRef={{
                  ...register("phoneNumber", numberFormValidation(true, 2, 11)),
                }}
              />

              <FormInput
                label="Save Beneficiary?"
                type="checkbox"
                readOnly={isSubmitting}
                errorMessage={errors?.saveBeneficiary?.message}
                inputRef={{
                  ...register("saveBeneficiary", basicFormValidation(false)),
                }}
              />
            </div>

            <div className="action">
              <Button
                text="Next"
                type="submit"
                loading={isSubmitting}
                disabled={!isValid}
              />
            </div>
          </form>
        )}

        {step === 2 && (
          <div className="select_payment enter_pin">
            <div className="transaction_summary">
              <h5>{watchType}</h5>
              <p>{watchNetwork}</p>
              <h6>{watchPhoneNumber}</h6>
            </div>

            <EnterPin
              showText={true}
              text="Enter the OTP sent to your email"
              pinLength={4}
              pin={otp}
              onChange={(value) => setOtp(value)}
              isDisabled={isSubmitting}
            />

            <EnterPin
              showText={true}
              pin={pin}
              onChange={handlePinChange}
              isDisabled={isSubmitting}
            />

            {isSubmitting && <Loader />}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AirtimeDataModal;
