import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import UserNavigations from "../dashbase/user-navigations";

import Logo from "./logo";

import Icon from "../assets/svg";

import { useAppDispatch } from "../redux";
import { toggleSidebar } from "../redux/components/components-slice";
import { logoutUserAction } from "../redux/user/user-slice";

const Sidebar = () => {
  const dispatch = useAppDispatch();

  const { isSidebarOpen } = useSelector((state: any) => state.componentsSlice);

  let navigations =
    UserNavigations?.slice(0, UserNavigations?.length - 1) || [];
  navigations = navigations?.filter((x) => !x?.unlist);

  const logoutEl = UserNavigations?.slice(
    UserNavigations?.length - 1,
    UserNavigations?.length
  )?.[0];

  const handleSidebarToggle = () => {
    if (window.innerWidth < 851) {
      dispatch(toggleSidebar(!isSidebarOpen));
    }
  };
  useEffect(() => {
    handleSidebarToggle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`sidebar_container${isSidebarOpen ? " open" : ""}`}>
      <div className="sidebar_container_inner">
        {window.innerWidth < 851 && (
          <div
            className="sidebar_toggler"
            onClick={() => dispatch(toggleSidebar(false))}
          >
            <Icon name="close" />
          </div>
        )}

        <div className="padding_left_right">
          <Logo />
        </div>

        <div className="links_container">
          {navigations?.map((nav, i) => (
            <React.Fragment key={i}>
              {nav?.to ? (
                <NavLink
                  className="link"
                  to={nav?.to}
                  onClick={handleSidebarToggle}
                >
                  <Icon name={nav?.icon} />
                  {nav?.label}
                </NavLink>
              ) : (
                <a
                  href={nav?.href || ""}
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  <Icon name={nav?.icon} />
                  {nav?.label}
                </a>
              )}
            </React.Fragment>
          ))}

          <div
            className="link logout"
            onClick={() => {
              handleSidebarToggle();
              dispatch(logoutUserAction());
            }}
          >
            <Icon name={logoutEl?.icon} />
            {logoutEl?.label}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
