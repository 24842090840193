export const APP_NAME = "PecanTrust";

export const APP_USER = "pt_app_user";

export const APP_BANKS = "pt_app_banks";

export const APP_BENEFICIARIES = "pt_app_beneficiaries";

export const APP_BILL_BENEFICIARIES = "pt_app_bill_beneficiaries";

export const APP_NETWORK_PROVIDERS = "pt_app_network_providers";

export const APP_BILL_PROVIDERS = "pt_app_bill_providers";
