export const Home = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 13.5V11.25"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.55252 2.11501L2.35502 6.27751C1.77002 6.74251 1.39502 7.72501 1.52252 8.46001L2.52002 14.43C2.70002 15.495 3.72002 16.3575 4.80002 16.3575H13.2C14.2725 16.3575 15.3 15.4875 15.48 14.43L16.4775 8.46001C16.5975 7.72501 16.2225 6.74251 15.645 6.27751L10.4475 2.12251C9.64502 1.47751 8.34752 1.47751 7.55252 2.11501Z"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
