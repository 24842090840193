import { useState, useEffect } from "react";
import { useAppDispatch } from "../../redux";

import BvnSection from "../../pages/auth/onboarding/bvn";
import OtpSection from "../../pages/auth/onboarding/otp";

import Loader from "../../components/loader";

import { getBvnAction, updateBvnAction } from "../../redux/user/user-slice";

const BVN = () => {
  const dispatch = useAppDispatch();

  const [isFetching, setIsFetching] = useState(false);
  const [step, setStep] = useState(1);
  const [bvnInfo, setBvnInfo] = useState<string | null>(null);

  useEffect(() => {
    if (!bvnInfo) {
      getBvnInfo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBvnInfo = () => {
    setIsFetching(true);

    dispatch(getBvnAction()).then((res) => {
      setBvnInfo(res);
      setIsFetching(false);
    });
  };

  return (
    <div className="residential_section bvn_section">
      {isFetching ? (
        <Loader />
      ) : (
        <>
          {step === 1 && (
            <BvnSection
              defaultBvn={bvnInfo ? bvnInfo : ""}
              hideNoBvnBtn={true}
              noBnvConfirmation={() => {}}
              onSubmitSuccess={() => setStep(2)}
            />
          )}

          {step === 2 && (
            <OtpSection
              funcToUse={updateBvnAction}
              cancelSession={() => setStep(1)}
              onSubmitSuccess={() => {
                getBvnInfo();
                setStep(1);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default BVN;
