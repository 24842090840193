import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import FormInput from "../../../components/form-input";
import Button from "../../../components/button";

import {
  sendOtpAction,
  sendEmailConfirmAction,
} from "../../../redux/user/user-slice";

import { numberFormValidation } from "../../../utils/functions";

type Props = {
  onSubmitSuccess: any;
};

type FormInputs = {
  otp: number;
};

const EmailOtpSection = ({ onSubmitSuccess }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();

  const { user } = useSelector((state: any) => state.userSlice);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendOtp = () => {
    setIsSubmitting(true);

    dispatch(sendOtpAction({ email: user?.email })).then(() => {
      setIsSubmitting(false);
    });
  };

  const submitFormOnSubmit: SubmitHandler<FormInputs> = (data) => {
    setIsSubmitting(true);

    dispatch(sendEmailConfirmAction({ otp: data?.otp })).then((res) => {
      if (res === true) {
        onSubmitSuccess();
      }
      setIsSubmitting(false);
    });
  };

  return (
    <React.Fragment>
      <form
        className="form bvn_form"
        onSubmit={handleSubmit(submitFormOnSubmit)}
      >
        <div className="form_inner">
          <h5>Email Verification</h5>
          <h6>
            Enter the one-time password (OTP) sent to{" "}
            {user?.email.replaceAll(/(?<=\w{3,})\w/g, "*")}
          </h6>

          <FormInput
            label="OTP"
            type="number"
            readOnly={isSubmitting}
            errorMessage={errors?.otp?.message}
            inputRef={{
              ...register("otp", numberFormValidation(true, 4, 4)),
            }}
          />

          <div className="actions">
            <Button
              text="Continue"
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
            />

            <div className="didnt_get_otp">
              <p>Didn't receive the OTP?</p>
              <div className="btn_text" onClick={sendOtp}>
                Resend
              </div>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default EmailOtpSection;
