import axios from "axios";

import { getUserDetails } from "../utils/functions";

export const API_URLS = {
  register: "/api/register",
  login: "/api/login",
  otp: "/api/otp/send",
  emailConfirm: "/api/onboarding/email_confirmation",
  securityQuestions: "/api/security_questions",
  personalInfo: "/api/onboarding/personal_information",
  bvnValidation: "/api/onboarding/bvn_validation",
  bvnConfirmation: "/api/onboarding/bvn_confirmation",
  pinCreation: "/api/onboarding/pin",
  moreInfo: "/api/onboarding/more_information",
  createAccount: "/api/onboarding/account/create",
  accounts: "/api/accounts",
  transactionsByAccount: "/api/account/transactions",
  banks: "/api/transfer/banks",
  beneficiaries: "/api/transfer/beneficiaries",
  billBeneficiaries: "/api/bills/beneficiaries",
  validateInterBankAccount: "/api/transfer/interbank/validate",
  validateIntraBankAccount: "/api/transfer/pecan/validate",
  interBankTransfer: "/api/transfer/interbank/transfer",
  intraBankTransfer: "/api/transfer/pecan/transfer",
  billProviders: "/api/bills/providers",
  billPayment: "/api/bills/payment",
  networkProviders: "/api/bills/network/providers",
  dataPlans: "/api/bills/data/plans",
  airtimePurchase: "/api/bills/airtime/payment",
  dataPurchase: "/api/bills/data/payment",
  generalStatement: "/api/statement/general",
  referrals: "/api/referrals",
  profileInformation: "/api/profile_management/profile",
  residenceAddress: "/api/profile_management/address",
  nextOfKin: "/api/profile_management/next_of_kin",
  documents: "/api/profile_management/documents",
  bvn: "/api/profile_management/bvn",
  changePassword: "/api/settings/change_password",
  changePin: "/api/settings/change_pin",
  forgotPin: "/api/settings/forgot_pin",
  securityQuestion: "/api/settings/security_question",
  logout: "/api/logout",
};

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
  headers: {},
});

// Request interceptors for API calls
API.interceptors.request.use(
  async config => {
    config.headers = { 
      'Authorization': `Bearer ${getUserDetails()?.access_token}`,
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

type GetRequestProps = {
  url: string;
  params: any;
};
export const getRequest = async ({ url, params }: GetRequestProps) => {
  const requestResponse = await API({
    url,
    method: "get",
    params,
  });

  return requestResponse;
};

type PostRequestProps = {
  url: string;
  params: any;
  data: any;
  formData: any;
};
export const postRequest = async ({
  url,
  params,
  data,
  formData,
}: PostRequestProps) => {
  const requestResponse = await API({
    headers: {
      "Content-Type": formData ? "multipart/form-data" : "application/json",
    },
    url,
    method: "post",
    params,
    data,
  });

  return requestResponse;
};

type PatchRequestProps = {
  url: string;
  params: any;
  data: any;
};
export const patchRequest = async ({
  url,
  params,
  data,
}: PatchRequestProps) => {
  const requestResponse = await API({
    url,
    method: "patch",
    params,
    data,
  });

  return requestResponse;
};

type DeleteRequestProps = {
  url: string;
  params: any;
  data: any;
};
export const deleteRequest = async ({
  url,
  params,
  data,
}: DeleteRequestProps) => {
  const requestResponse = await API({
    url,
    method: "delete",
    params,
    data,
  });

  return requestResponse;
};
