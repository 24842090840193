import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../redux";

import EmailOtpSection from "./email-otp";
import PersonalInfoPage from "./personal-info";
import BvnSection from "./bvn";
import OtpSection from "./otp";
import CreatePinSection from "./create-pin";
import ABitMoreInfo from "./a-bit-more-info";
import CompleteSection from "./complete";

import { logoutUserAction } from "../../../redux/user/user-slice";

const OnboardingPage = () => {
  const dispatch = useAppDispatch();

  const { user } = useSelector((state: any) => state.userSlice);
  const [currentStep, setCurrentStep] = useState(user?.onboarding || 0);

  useEffect(() => {
    window.scroll(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToNextStep = () => {
    if (currentStep === 1) {
      setCurrentStep(1.5);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  if (user?.onboarding === 4) return <>{window.location.assign("/home")}</>;

  return (
    <React.Fragment>
      <div className="sign_in_page onboarding_page">
        <div className="form_container">
          {!user?.email_verified && currentStep === 0 && (
            <EmailOtpSection onSubmitSuccess={goToNextStep} />
          )}
          {user?.email_verified && currentStep === 0 && (
            <PersonalInfoPage onSubmitSuccess={goToNextStep} />
          )}
          {currentStep === 1 && (
            <BvnSection
              onSubmitSuccess={goToNextStep}
              noBnvConfirmation={() => setCurrentStep(2)}
            />
          )}
          {currentStep === 1.5 && <OtpSection onSubmitSuccess={goToNextStep} />}
          {currentStep === 2 && (
            <CreatePinSection onSubmitSuccess={goToNextStep} />
          )}
          {currentStep === 3 && <ABitMoreInfo onSubmitSuccess={goToNextStep} />}
          {currentStep === 4 && <CompleteSection />}
        </div>

        <div className="logout" onClick={() => dispatch(logoutUserAction())}>
          Logout
        </div>
      </div>
    </React.Fragment>
  );
};

export default OnboardingPage;
