export const Call = () => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.8701 26.2587C31.8701 26.8137 31.7468 27.3842 31.4847 27.9392C31.2226 28.4942 30.8834 29.0183 30.4363 29.5117C29.6809 30.3442 28.8484 30.9454 27.908 31.3308C26.983 31.7162 25.9809 31.9167 24.9018 31.9167C23.3293 31.9167 21.6488 31.5467 19.8759 30.7912C18.103 30.0358 16.3301 29.0183 14.5726 27.7387C12.7997 26.4437 11.1193 25.01 9.51592 23.4221C7.92801 21.8187 6.49426 20.1383 5.21467 18.3808C3.95051 16.6233 2.93301 14.8658 2.19301 13.1237C1.45301 11.3662 1.08301 9.68583 1.08301 8.0825C1.08301 7.03416 1.26801 6.03208 1.63801 5.10708C2.00801 4.16666 2.59384 3.30333 3.41092 2.5325C4.39759 1.56125 5.47676 1.08333 6.61759 1.08333C7.04926 1.08333 7.48092 1.17583 7.86634 1.36083C8.26717 1.54583 8.62176 1.82333 8.89926 2.22416L12.4759 7.26541C12.7534 7.65083 12.9538 8.00541 13.0926 8.34458C13.2313 8.66833 13.3084 8.99208 13.3084 9.285C13.3084 9.655 13.2005 10.025 12.9847 10.3796C12.7843 10.7342 12.4913 11.1042 12.1213 11.4742L10.9497 12.6921C10.7801 12.8617 10.703 13.0621 10.703 13.3087C10.703 13.4321 10.7184 13.54 10.7493 13.6633C10.7955 13.7867 10.8418 13.8792 10.8726 13.9717C11.1501 14.4804 11.628 15.1433 12.3063 15.945C13.0001 16.7467 13.7401 17.5637 14.5418 18.3808C15.3743 19.1979 16.1759 19.9533 16.993 20.6471C17.7947 21.3254 18.4576 21.7879 18.9818 22.0654C19.0588 22.0962 19.1513 22.1425 19.2593 22.1887C19.3826 22.235 19.5059 22.2504 19.6447 22.2504C19.9068 22.2504 20.1072 22.1579 20.2768 21.9883L21.4484 20.8321C21.8338 20.4467 22.2038 20.1537 22.5584 19.9687C22.913 19.7529 23.2676 19.645 23.653 19.645C23.9459 19.645 24.2543 19.7067 24.5934 19.8454C24.9326 19.9842 25.2872 20.1846 25.6726 20.4467L30.7755 24.0696C31.1763 24.3471 31.4538 24.6708 31.6234 25.0562C31.7776 25.4417 31.8701 25.8271 31.8701 26.2587Z"
      stroke="#FF5001"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);
