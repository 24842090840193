import React from "react";

import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

type Props = {
  activeTab: string;
  toggleTab: (id: string) => void;
  navItems: {
    id: string;
    label: string;
  }[];
  tabPanes: {
    id: string;
    content: React.ReactNode;
  }[];
};

const NavTabs = ({ activeTab, toggleTab, navItems, tabPanes }: Props) => (
  <React.Fragment>
    <div className="vertical_scroll">
      <Nav tabs>
        {navItems?.map((item, i) => (
          <NavItem key={i}>
            <NavLink
              className={classnames({ active: activeTab === item?.id })}
              onClick={() => toggleTab(item?.id)}
            >
              {item?.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </div>
    <TabContent activeTab={activeTab}>
      {tabPanes?.map((item, i) => (
        <TabPane key={i} tabId={item?.id}>
          {item?.content}
        </TabPane>
      ))}
    </TabContent>
  </React.Fragment>
);

export default NavTabs;
