import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { useForm, SubmitHandler } from "react-hook-form";
import moment from "moment";

import PageTitle from "../../components/page-title";
import TopBar from "../../components/top-bar";
import Button from "../../components/button";
import FormInput from "../../components/form-input";
import FormSelect from "../../components/form-select";
import DatePickerComponent from "../../components/datepicker";
import EnterPin from "../../components/enter-pin";
import Loader from "../../components/loader";

import { sendGeneralStatementAction } from "../../redux/user/user-slice";

import {
  basicFormValidation,
  emailFormValidation,
} from "../../utils/functions";
import { generalStatement } from "../../assets/img";

type FormInputs = {
  account: string;
  email: string;
};

const StatementPage = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();

  const { user } = useSelector((state: any) => state.userSlice);

  const [type, setType] = useState<string | null>("General Statement");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pin, setPin] = useState<string>("");
  const [requestData, setRequestData] = useState<any | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePinChange = (value: string) => {
    setPin(value);
  };
  useEffect(() => {
    if (pin?.length === 4) {
      let timer = setInterval(function () {
        clearInterval(timer);

        sendAccountStatement();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  const sendAccountStatement = async () => {
    setIsSubmitting(true);

    await dispatch(
      sendGeneralStatementAction({
        ...requestData,
        pin: pin,
      })
    ).then((res) => {
      if (res === true) {
        resetFormValues();
      }
    });
    setIsSubmitting(false);
  };

  const onDateChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const submitFormOnSubmit: SubmitHandler<FormInputs> = (data) => {
    const payload = {
      from_date: moment(startDate).format("YYYY-MM-DD"),
      to_date: moment(endDate).format("YYYY-MM-DD"),
      account: data?.account,
      receiving_email: data?.email,
    };
    console.log(payload, "payload");

    setRequestData(payload);
  };

  const resetFormValues = () => {
    reset();
    setPin("");
    setRequestData(null);
  };

  const types = [
    { image: generalStatement, name: "General Statement", fee: "Free" },
    // {
    //   image: signedStatement,
    //   name: "Signed Statement",
    //   fee: formatAmount(1000, 2),
    // },
  ];

  var accountList: {
    label: string;
    value: string;
  }[] = [];
  accountList = user?.all_accounts?.map((x: any) => ({
    label: `${x?.account_type} - ${x?.account_number}`,
    value: x?.account_number,
  }));

  return (
    <React.Fragment>
      <PageTitle title="Talk To Us" />
      <TopBar titles={["Talk To Us"]} />

      <div className="statement_page_container">
        <div className="title">Request Account Statement</div>

        {!requestData ? (
          <>
            <div className="statement_types">
              {types?.map(({ image, name, fee }, i) => (
                <div
                  key={i}
                  className={`item ${type === name ? `active` : ``}`}
                  onClick={() => setType(name)}
                >
                  <img src={image} alt="" />
                  <h6>{name}</h6>
                  <p className={`fee ${fee === "Free" ? `free` : ``}`}>{fee}</p>
                </div>
              ))}
            </div>

            <form className="form" onSubmit={handleSubmit(submitFormOnSubmit)}>
              <FormSelect
                label="SELECT ACCOUNT"
                name="securityQuestion"
                type="default"
                defaultValue=""
                errorMessage={errors?.account?.message}
                selectOptions={accountList}
                selectRef={{
                  ...register("account", basicFormValidation(true)),
                }}
              />

              <FormInput
                label="RECEIVING EMAIL"
                type="text"
                readOnly={isSubmitting}
                errorMessage={errors?.email?.message}
                inputRef={{
                  ...register("email", emailFormValidation(true)),
                }}
              />

              <DatePickerComponent
                onChange={onDateChange}
                startDate={startDate}
                endDate={endDate}
                range={true}
                showYearDropdown={true}
                placeholderText="Choose date range"
              />

              <div className="action">
                <Button
                  text="Request"
                  type="submit"
                  loading={isSubmitting}
                  disabled={!isValid || !startDate || !endDate}
                />
              </div>
            </form>
          </>
        ) : (
          <>
            <br />
            <br />
            <EnterPin
              showText={true}
              pin={pin}
              onChange={handlePinChange}
              isDisabled={isSubmitting}
            />

            {isSubmitting && <Loader />}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default StatementPage;
