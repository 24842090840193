import { useState, useEffect } from "react";
import { useAppDispatch } from "../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from "../../components/button";
import FormInput from "../../components/form-input";
import Loader from "../../components/loader";

import {
  getResidenceAction,
  updateResidenceAction,
} from "../../redux/user/user-slice";

import { textFormValidation } from "../../utils/functions";

type FormInputs = {
  address: string;
  landmark: string;
  state: string;
  lga: string;
  postal_address: string;
};

const ResidentialAddress = () => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const [isFetching, setIsFetching] = useState(false);
  const [residenceInfo, setResidenceInfo] = useState<any | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!residenceInfo) {
      setIsFetching(true);

      dispatch(getResidenceAction()).then((res) => {
        setResidenceInfo(res);
        setIsFetching(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (residenceInfo) {
      setValue("address", residenceInfo?.address);
      setValue("landmark", residenceInfo?.landmark);
      setValue("state", residenceInfo?.state);
      setValue("lga", residenceInfo?.lga);
      setValue("postal_address", residenceInfo?.postal_address);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residenceInfo]);

  const submitFormOnSubmit: SubmitHandler<FormInputs> = async (data) => {
    setIsSubmitting(true);

    await dispatch(
      updateResidenceAction({
        ...data,
      })
    );
    setIsSubmitting(false);
  };

  return (
    <div className="residential_section">
      {isFetching ? (
        <Loader />
      ) : (
        <form className="form" onSubmit={handleSubmit(submitFormOnSubmit)}>
          <FormInput
            label="ADDRESS"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.address?.message}
            inputRef={{
              ...register("address", textFormValidation(true, 2)),
            }}
          />
          <FormInput
            label="NEAREST LANDMARK"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.landmark?.message}
            inputRef={{
              ...register("landmark", textFormValidation(true, 2)),
            }}
          />
          <FormInput
            label="STATE"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.state?.message}
            inputRef={{
              ...register("state", textFormValidation(true, 2)),
            }}
          />
          <FormInput
            label="LGA"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.lga?.message}
            inputRef={{
              ...register("lga", textFormValidation(true, 2)),
            }}
          />
          <FormInput
            label="POSTAL ADDRESS"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.postal_address?.message}
            inputRef={{
              ...register("postal_address", textFormValidation(true, 2)),
            }}
          />

          <div className="action">
            <Button
              text="Save Changes"
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default ResidentialAddress;
