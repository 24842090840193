import OtpInput from "react-otp-input";

type Props = {
  className?: string;
  showText: boolean;
  text?: string;
  pin: string;
  pinLength?: number;
  onChange: (e: any) => void;
  isDisabled: boolean;
};

const EnterPin = ({
  className,
  showText,
  text,
  pin,
  pinLength,
  onChange,
  isDisabled,
}: Props) => {
  return (
    <div className={`enter_pin_container${className ? ` ${className}` : ``}`}>
      {showText && (
        <h5>{text ? text : "Enter your Secret PIN to confirm transaction"}</h5>
      )}

      <div className="pin_container">
        <OtpInput
          value={pin}
          onChange={onChange}
          numInputs={pinLength ? pinLength : 4}
          separator={<span>-</span>}
          isInputSecure={text ? false : true}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default EnterPin;
