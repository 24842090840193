import { useState, useEffect } from "react";
import { useAppDispatch } from "../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from "../../components/button";
import FormInput from "../../components/form-input";
import Loader from "../../components/loader";

import {
  getDocumentsAction,
  updateDocumentsAction,
} from "../../redux/user/user-slice";

import { basicFormValidation } from "../../utils/functions";

type FormInputs = {
  photograph: boolean;
  signature: boolean;
};

const Documents = () => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const [isFetching, setIsFetching] = useState(false);
  const [documentsInfo, setDocumentsInfo] = useState<any | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!documentsInfo) {
      setIsFetching(true);

      dispatch(getDocumentsAction()).then((res) => {
        setDocumentsInfo(res);
        setIsFetching(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitFormOnSubmit: SubmitHandler<FormInputs> = async (data: any) => {
    setIsSubmitting(true);

    const payload = new FormData();
    payload.append("photograph", data.photograph[0]);
    payload.append("signature", data.signature[0]);

    await dispatch(updateDocumentsAction(payload));
    setIsSubmitting(false);
  };

  return (
    <div className="residential_section">
      {isFetching ? (
        <Loader />
      ) : (
        <form className="form" onSubmit={handleSubmit(submitFormOnSubmit)}>
          <FormInput
            label="UPLOAD SELFIE IMAGE"
            type="file"
            readOnly={isSubmitting}
            errorMessage={errors?.photograph?.message}
            inputRef={{
              ...register("photograph", basicFormValidation(true)),
            }}
            accept="image/jpg,image/jpeg,image/png"
          />
          {documentsInfo?.photograph && (
            <p className="exists">
              * You have previously uploaded a Selfie Image.
            </p>
          )}
          <FormInput
            label="UPLOAD SIGNATURE"
            type="file"
            readOnly={isSubmitting}
            errorMessage={errors?.signature?.message}
            inputRef={{
              ...register("signature", basicFormValidation(true)),
            }}
            accept="image/jpg,image/jpeg,image/png,application/pdf"
          />
          {documentsInfo?.signature && (
            <p className="exists">
              * You have previously uploaded a Signature.
            </p>
          )}

          <div className="action">
            <Button
              text="Save Changes"
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default Documents;
