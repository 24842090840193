import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";

import FormInput from "../../components/form-input";
import Button from "../../components/button";
import Logo from "../../components/logo";

import { useAppDispatch } from "../../redux";
import { authRegisterUserAction } from "../../redux/user/user-slice";

import {
  emailFormValidation,
  numberFormValidation,
  passwordFormValidation,
} from "../../utils/functions";

type FormInputs = {
  email: string;
  phone: string;
  password: string;
  password_confirmation: string;
};

const RegisterPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors, isValid },
    clearErrors,
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: any) => state.userSlice);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const watchPassword = watch("password", "");
  const watchPassword_confirmation = watch("password_confirmation", "");

  useEffect(() => {
    if (watchPassword !== watchPassword_confirmation) {
      setError("password_confirmation", {
        type: "manual",
        message: "Passwords does not match!",
      });
    } else {
      clearErrors("password_confirmation");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPassword_confirmation]);

  const submitFormOnSubmit: SubmitHandler<FormInputs> = (data) => {
    setIsSubmitting(true);

    dispatch(authRegisterUserAction(data)).then((res: any) => {
      if (res === true) {
        window.location.assign("/onboarding");
      }
    });
    setIsSubmitting(false);
  };

  if (user?.account_created && !isSubmitting)
    return <>{window.location.assign("/home")}</>;

  return (
    <React.Fragment>
      <div className="sign_in_page register_page">
        <div className="form_container">
          <Logo />

          <form className="form" onSubmit={handleSubmit(submitFormOnSubmit)}>
            <div className="form_inner">
              <h5>Create Account</h5>
              <h6>We request these information to help secure your account.</h6>

              <FormInput
                label="EMAIL"
                type="email"
                readOnly={isSubmitting}
                errorMessage={errors?.email?.message}
                inputRef={{
                  ...register("email", emailFormValidation(true)),
                }}
              />
              <FormInput
                label="PHONE NUMBER"
                type="number"
                readOnly={isSubmitting}
                errorMessage={errors?.phone?.message}
                inputRef={{
                  ...register("phone", numberFormValidation(true, 11, 11)),
                }}
              />
              <FormInput
                label="PASSWORD"
                type="password"
                readOnly={isSubmitting}
                errorMessage={errors?.password?.message}
                inputRef={{
                  ...register("password", passwordFormValidation(true)),
                }}
              />
              <FormInput
                label="CONFIRM PASSWORD"
                type="password"
                readOnly={isSubmitting}
                errorMessage={errors?.password_confirmation?.message}
                inputRef={{
                  ...register(
                    "password_confirmation",
                    passwordFormValidation(true)
                  ),
                }}
              />

              <div className="actions">
                <Button
                  text="Continue"
                  type="submit"
                  loading={isSubmitting}
                  disabled={!isValid}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RegisterPage;
