import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import FormInput from "../../../components/form-input";
import FormSelect from "../../../components/form-select";
import Button from "../../../components/button";

import {
  getSecurityQuestionsAction,
  addPersonalInfoAction,
} from "../../../redux/user/user-slice";

// import { authLoginUserAction } from "../../../redux/user/user-slice";

import {
  nameFormValidation,
  textFormValidation,
  basicFormValidation,
} from "../../../utils/functions";

type Props = {
  onSubmitSuccess: any;
};

type FormInputs = {
  firstName: string;
  lastName: string;
  otherName: string;
  referralCode: string;
  securityQuestion: string;
  securityAnswer: string;
};

const PersonalInfoSection = ({ onSubmitSuccess }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();

  const [questions, setQuestions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);

    dispatch(getSecurityQuestionsAction()).then((res: any) => {
      setQuestions(
        res?.map((x: any) => ({
          label: x?.question,
          value: x?.id,
        }))
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitFormOnSubmit: SubmitHandler<FormInputs> = (data) => {
    setIsSubmitting(true);

    var payload = {
      first_name: data.firstName,
      last_name: data.lastName,
      other_name: data.otherName,
      security_question: data.securityQuestion || "1",
      security_answer: data.securityAnswer,
    };
    if (data.referralCode) {
      payload = Object.assign(
        {},
        { referral_code: data.referralCode },
        payload
      );
    }

    dispatch(addPersonalInfoAction(payload)).then((res: any) => {
      if (res === true) {
        onSubmitSuccess();
      }
      setIsSubmitting(false);
    });
  };

  return (
    <React.Fragment>
      <form className="form" onSubmit={handleSubmit(submitFormOnSubmit)}>
        <div className="form_inner">
          <h5>Personal Information</h5>
          <h6>We’d love to know you, tell us about yourself.</h6>

          <FormInput
            label="FIRST NAME"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.firstName?.message}
            inputRef={{
              ...register("firstName", nameFormValidation(true)),
            }}
          />
          <FormInput
            label="LAST NAME"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.lastName?.message}
            inputRef={{
              ...register("lastName", nameFormValidation(true)),
            }}
          />
          <FormInput
            label="OTHER NAME (Optional)"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.otherName?.message}
            inputRef={{
              ...register("otherName", nameFormValidation(false)),
            }}
          />
          <FormInput
            label="REFERRAL CODE (Optional)"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.referralCode?.message}
            inputRef={{
              ...register("referralCode", textFormValidation(false)),
            }}
          />

          <p className="form-section-title">Set Security Question</p>
          <FormSelect
            name="securityQuestion"
            label="SECURITY QUESTION"
            type="default"
            defaultValue=""
            errorMessage={errors?.securityQuestion?.message}
            selectOptions={questions}
            selectRef={{
              ...register("securityQuestion", basicFormValidation(false)),
            }}
          />

          <FormInput
            label="ANSWER"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.securityAnswer?.message}
            inputRef={{
              ...register("securityAnswer", textFormValidation(true)),
            }}
          />

          <div className="actions">
            <Button
              text="Continue"
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
            />
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default PersonalInfoSection;
