import { Switch, Route, Redirect } from "react-router-dom";

import UserNavigations from "./user-navigations";

const UserRoutes = () => {
  let navs = UserNavigations || [];

  const routes = navs?.filter((x: any) => x?.to);

  const paths = routes?.map((x) => x?.to);

  const pathname = window.location.pathname;

  return (
    <Switch>
      {routes?.map((route, i) => (
        <Route
          key={i}
          exact
          path={route?.to || ""}
          component={route?.component}
        />
      ))}

      <Route
        path="*"
        render={() => (
          <Redirect to={paths.includes(pathname) ? pathname : "/home"} />
        )}
      />
    </Switch>
  );
};

export default UserRoutes;
