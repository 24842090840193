export const Logout = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.67499 5.66999C6.90749 2.96999 8.29499 1.86749 11.3325 1.86749H11.43C14.7825 1.86749 16.125 3.20999 16.125 6.56249V11.4525C16.125 14.805 14.7825 16.1475 11.43 16.1475H11.3325C8.31749 16.1475 6.92999 15.06 6.68249 12.405"
      stroke="#DB3D46"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.25 9H2.71503"
      stroke="#DB3D46"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.3875 6.48749L1.875 8.99999L4.3875 11.5125"
      stroke="#DB3D46"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
