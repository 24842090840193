import { BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import UserRoutes from "./routes";

import Sidebar from "../components/sidebar";

const Dashbase = () => {
  const { isSidebarOpen } = useSelector((state: any) => state.componentsSlice);

  return (
    <BrowserRouter>
      <div className={`dashboard${isSidebarOpen ? "" : " hide_sidebar"}`}>
        <Sidebar />
        <div className="dashboard_content">
          <UserRoutes />
        </div>
      </div>
    </BrowserRouter>
  );
};

export default Dashbase;
