const Loader = () => (
  <div className="loader_container">
    <img
      src="https://res.cloudinary.com/emekamykael45/image/upload/v1620338359/InventSoft/loader_blue.svg"
      className="loader_img"
      alt="Loader"
    />
  </div>
);

export default Loader;
