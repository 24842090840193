export const Eye = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.89172 12.7333C7.73339 12.7333 7.57506 12.6749 7.45006 12.5499C6.76672 11.8666 6.39172 10.9583 6.39172 9.99994C6.39172 8.00827 8.00839 6.3916 10.0001 6.3916C10.9584 6.3916 11.8667 6.7666 12.5501 7.44993C12.6667 7.5666 12.7334 7.72494 12.7334 7.8916C12.7334 8.05827 12.6667 8.2166 12.5501 8.33327L8.33339 12.5499C8.20839 12.6749 8.05006 12.7333 7.89172 12.7333ZM10.0001 7.6416C8.70006 7.6416 7.64172 8.69993 7.64172 9.99994C7.64172 10.4166 7.75006 10.8166 7.95006 11.1666L11.1667 7.94993C10.8167 7.74993 10.4167 7.6416 10.0001 7.6416Z"
      fill="#989898"
    />
    <path
      d="M4.66666 15.4251C4.52499 15.4251 4.37499 15.3751 4.25833 15.2751C3.36666 14.5167 2.56666 13.5834 1.88333 12.5001C0.999992 11.1251 0.999992 8.8834 1.88333 7.50006C3.91666 4.31673 6.87499 2.4834 9.99999 2.4834C11.8333 2.4834 13.6417 3.11673 15.225 4.3084C15.5 4.51673 15.5583 4.9084 15.35 5.1834C15.1417 5.4584 14.75 5.51673 14.475 5.3084C13.1083 4.27506 11.5583 3.7334 9.99999 3.7334C7.30833 3.7334 4.73333 5.35007 2.93333 8.17507C2.30833 9.15007 2.30833 10.8501 2.93333 11.8251C3.55833 12.8001 4.27499 13.6417 5.06666 14.3251C5.32499 14.5501 5.35833 14.9417 5.13333 15.2084C5.01666 15.3501 4.84166 15.4251 4.66666 15.4251Z"
      fill="#989898"
    />
    <path
      d="M10 17.5166C8.89168 17.5166 7.80834 17.2916 6.76668 16.8499C6.45001 16.7166 6.30001 16.3499 6.43334 16.0333C6.56668 15.7166 6.93334 15.5666 7.25001 15.6999C8.13334 16.0749 9.05834 16.2666 9.99168 16.2666C12.6833 16.2666 15.2583 14.6499 17.0583 11.8249C17.6833 10.8499 17.6833 9.14992 17.0583 8.17492C16.8 7.76658 16.5167 7.37492 16.2167 7.00825C16 6.74158 16.0417 6.34992 16.3083 6.12492C16.575 5.90825 16.9667 5.94158 17.1917 6.21658C17.5167 6.61658 17.8333 7.04992 18.1167 7.49992C19 8.87492 19 11.1166 18.1167 12.4999C16.0833 15.6833 13.125 17.5166 10 17.5166Z"
      fill="#989898"
    />
    <path
      d="M10.575 13.5584C10.2834 13.5584 10.0167 13.35 9.95836 13.05C9.89169 12.7084 10.1167 12.3834 10.4584 12.325C11.375 12.1584 12.1417 11.3917 12.3084 10.475C12.375 10.1334 12.7 9.91671 13.0417 9.97504C13.3834 10.0417 13.6084 10.3667 13.5417 10.7084C13.275 12.15 12.125 13.2917 10.6917 13.5584C10.65 13.55 10.6167 13.5584 10.575 13.5584Z"
      fill="#989898"
    />
  </svg>
);
