export const Checkmark = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.50008 14.5834C3.58796 14.5834 0.416748 11.4122 0.416748 7.50008C0.416748 3.58796 3.58796 0.416748 7.50008 0.416748C11.4122 0.416748 14.5834 3.58796 14.5834 7.50008C14.5834 11.4122 11.4122 14.5834 7.50008 14.5834ZM6.79387 10.3334L11.8018 5.32479L10.8002 4.32321L6.79387 8.33025L4.79 6.32637L3.78841 7.32796L6.79387 10.3334Z"
      fill="#2AC66A"
    />
  </svg>
);
