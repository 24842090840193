export const Delete = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconly/Broken/Delete">
      <g id="Delete">
        <path
          id="Delete_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.04617 5.49695H20.286C20.6801 5.49695 21 5.82394 21 6.22794C21 6.63293 20.6801 6.95993 20.286 6.95993H3.71298C3.31894 6.95993 3 6.63293 3 6.22794C3 5.82394 3.31894 5.49695 3.71298 5.49695H6.90723C7.33248 5.49695 7.70214 5.18595 7.78504 4.75996L8.02303 3.53998C8.26394 2.61399 9.04422 2 9.93178 2H14.0682C14.9558 2 15.7361 2.61399 15.9662 3.49398C16.0687 3.88497 15.8424 4.28597 15.462 4.39096C15.0826 4.49596 14.6905 4.26397 14.5881 3.87297C14.5247 3.63198 14.3111 3.46398 14.0682 3.46398H9.93178C9.68892 3.46398 9.47532 3.63198 9.41192 3.87297L9.18467 5.04595C9.15345 5.20395 9.10469 5.35195 9.04617 5.49695ZM8.1547 20.4967C7.23788 20.4767 6.71899 19.9667 6.61268 18.9797C6.30642 16.1568 5.78169 9.47189 5.77681 9.40489C5.7456 9.00189 5.4013 8.7149 5.00824 8.7329C4.61517 8.7659 4.32257 9.11889 4.35475 9.52089C4.35963 9.58889 4.88534 16.2968 5.19355 19.1427C5.37789 20.8457 6.50051 21.9247 8.12544 21.9597H8.14007C8.52728 21.9597 8.84525 21.6427 8.85402 21.2437C8.86183 20.8397 8.54874 20.5047 8.1547 20.4967ZM18.9916 8.7332C18.6034 8.7122 18.2542 9.00119 18.223 9.40419C18.2181 9.47119 17.6895 16.1741 17.3862 18.975C17.2779 19.983 16.7522 20.482 15.7778 20.501L15.3905 20.508C14.1533 20.5302 13.5775 20.5406 12.1954 20.536H12.1934C11.7994 20.536 11.4804 20.861 11.4795 21.264C11.4775 21.669 11.7964 21.997 12.1905 21.999C12.4343 22 12.6772 22 12.921 22C13.9402 22 14.3473 21.9918 15.1116 21.9765C15.3121 21.9725 15.5371 21.968 15.8041 21.963C17.4954 21.932 18.618 20.875 18.8053 19.135C19.1106 16.3141 19.6402 9.58919 19.6451 9.52219C19.6772 9.11919 19.3846 8.7662 18.9916 8.7332Z"
          fill="#130F26"
        />
      </g>
    </g>
  </svg>
);
