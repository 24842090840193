export const Referrals = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.79501 8.205C11.4825 9.8925 11.4825 12.6225 9.79501 14.3025C8.10751 15.9825 5.37751 15.99 3.69751 14.3025C2.01751 12.615 2.01001 9.885 3.69751 8.205"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.94247 10.0575C6.18747 8.3025 6.18747 5.4525 7.94247 3.69C9.69747 1.9275 12.5475 1.935 14.31 3.69C16.0725 5.445 16.065 8.295 14.31 10.0575"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
