export const ChevronSquare = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.0833 21.875L32.0833 13.125C32.0833 5.83334 29.1666 2.91668 21.875 2.91668L13.125 2.91668C5.83329 2.91668 2.91662 5.83334 2.91663 13.125L2.91663 21.875C2.91663 29.1667 5.83329 32.0833 13.125 32.0833L21.875 32.0833C29.1666 32.0833 32.0833 29.1667 32.0833 21.875Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.6479 15.6625L17.5 20.7958L12.3521 15.6625"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
