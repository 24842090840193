import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import FormInput from "../../../components/form-input";
import Button from "../../../components/button";

import { createPinAction } from "../../../redux/user/user-slice";

import { numberFormValidation } from "../../../utils/functions";

type Props = {
  onSubmitSuccess: any;
};

type FormInputs = {
  secretPin: number;
  confirmSecretPin: number;
};

const CreatePinSection = ({ onSubmitSuccess }: Props) => {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors, isValid },
    clearErrors,
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const watchPin = watch("secretPin", 0);
  const watchConfirmPin = watch("confirmSecretPin", 0);

  useEffect(() => {
    if (watchPin !== watchConfirmPin) {
      setError("confirmSecretPin", {
        type: "manual",
        message: "Pins does not match!",
      });
    } else {
      clearErrors("confirmSecretPin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchConfirmPin]);

  const submitFormOnSubmit: SubmitHandler<FormInputs> = (data) => {
    setIsSubmitting(true);

    dispatch(
      createPinAction({
        pin: data.secretPin,
      })
    ).then((res: any) => {
      if (res === true) {
        onSubmitSuccess();
      }
      setIsSubmitting(false);
    });
  };

  return (
    <React.Fragment>
      <form className="form" onSubmit={handleSubmit(submitFormOnSubmit)}>
        <div className="form_inner">
          <h5>Create PIN</h5>
          <h6>
            This is your secret PIN. Keep it safe and do not share it with
            anyone
          </h6>

          <FormInput
            label="SECRET PIN"
            type="number"
            readOnly={isSubmitting}
            errorMessage={errors?.secretPin?.message}
            inputRef={{
              ...register("secretPin", numberFormValidation(true, 6, 6)),
            }}
          />
          <FormInput
            label="CONFIRM SECRET PIN"
            type="number"
            readOnly={isSubmitting}
            errorMessage={errors?.confirmSecretPin?.message}
            inputRef={{
              ...register("confirmSecretPin", numberFormValidation(true, 6, 6)),
            }}
          />

          <div className="actions">
            <Button
              text="Create Pin"
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
            />
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default CreatePinSection;
