type Props = {
  name: string;
  image: any;
  title: string;
  description: string;
  onClick: (name: string) => void;
};

const Action = ({ name, image, title, description, onClick }: Props) => {
  return (
    <div className="action" onClick={() => onClick(name)}>
      <img src={image} alt="Ad Banner" />

      <h6>{title}</h6>
      {description && <p>{description}</p>}
    </div>
  );
};

export default Action;
