import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";

import FormInput from "../../components/form-input";
import Button from "../../components/button";
import Logo from "../../components/logo";

import { authLoginUserAction } from "../../redux/user/user-slice";

import { emailFormValidation } from "../../utils/functions";

type FormInputs = {
  email: string;
};

const ForgotPasswordPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useDispatch();

  const { user } = useSelector((state: any) => state.userSlice);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const submitFormOnSubmit: SubmitHandler<FormInputs> = (data) => {
    console.log(data, "data");
    setIsSubmitting(true);

    dispatch(authLoginUserAction(data));
    setIsSubmitting(false);
  };

  if (user?.onboarding === 5 && !isSubmitting)
    return <>{window.location.assign("/home")}</>;

  return (
    <React.Fragment>
      <div className="sign_in_page">
        <div className="form_container">
          <Logo />

          <form className="form" onSubmit={handleSubmit(submitFormOnSubmit)}>
            <div className="form_inner">
              <h5>Password Recovery</h5>
              <h6>Provide your email address to recover your password</h6>

              <FormInput
                label="EMAIL"
                type="email"
                readOnly={isSubmitting}
                errorMessage={errors?.email?.message}
                inputRef={{
                  ...register("email", emailFormValidation(true)),
                }}
              />

              <div className="actions">
                <Button
                  text="Continue"
                  type="submit"
                  loading={isSubmitting}
                  disabled={!isValid}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgotPasswordPage;
