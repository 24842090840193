import { createSlice } from "@reduxjs/toolkit";

import { postRequest, getRequest } from "../../api";
import { API_URLS } from "../../api";

import { setAlert } from "../components/components-slice";

import {
  useSessionStorage,
  getUserDetails,
  getRequestError,
  logout,
} from "../../utils/functions";
import { APP_USER } from "../../utils/constants";

type Props = {
  user: {} | any;
  createdAccount: {} | any;
};
export const initialState: Props = {
  user: getUserDetails(),
  createdAccount: null,
};

// Slice
const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginSuccess: (state, { payload }) => {
      state.user = payload;
      useSessionStorage.set(APP_USER, payload);
    },
    createdAccountSuccess: (state, { payload }) => {
      state.createdAccount = payload;
    },
    logoutSuccess: (state) => {
      state.user = null;
      logout();
    },
  },
});
export default slice.reducer;

// Actions
const { loginSuccess, createdAccountSuccess, logoutSuccess } = slice.actions;

export const authLoginUserAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await postRequest({
      url: API_URLS?.login,
      data,
      params: null,
      formData: null,
    });

    dispatch(setAlert(true, "success", res.data?.message));
    dispatch(loginSuccess({ ...res.data?.data, email: data?.email }));
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const authRegisterUserAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await postRequest({
      url: API_URLS?.register,
      data,
      params: null,
      formData: null,
    });

    dispatch(setAlert(true, "success", res.data?.message));
    dispatch(loginSuccess({ ...res.data?.data, email: data?.email }));

    return res.data?.status;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const sendOtpAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await postRequest({
      url: API_URLS?.otp,
      data,
      params: null,
      formData: null,
    });

    dispatch(setAlert(true, "success", res.data?.message));
    return res?.data?.status;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const sendEmailConfirmAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await postRequest({
      url: API_URLS?.emailConfirm,
      data,
      params: null,
      formData: null,
    });

    dispatch(setAlert(true, "success", res.data?.message));
    return res.data?.status;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const getSecurityQuestionsAction = () => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: API_URLS?.securityQuestions,
      params: null,
    });
    return res.data?.data?.questions;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const updatePasswordAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await postRequest({
      url: API_URLS?.changePassword,
      data,
      params: null,
      formData: null,
    });

    dispatch(setAlert(true, "success", res.data?.message));
    return res.data?.status;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const updatePinAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await postRequest({
      url: API_URLS?.changePin,
      data,
      params: null,
      formData: null,
    });

    dispatch(setAlert(true, "success", res.data?.message));
    return res.data?.status;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const forgotPinAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await postRequest({
      url: API_URLS?.forgotPin,
      data,
      params: null,
      formData: null,
    });

    dispatch(setAlert(true, "success", res.data?.message));
    return res.data?.status;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const addPersonalInfoAction =
  (data: any) => async (dispatch: any, getState: any) => {
    try {
      const userState = getState()?.userSlice?.user;

      const res = await postRequest({
        url: API_URLS?.personalInfo,
        data,
        params: null,
        formData: null,
      });

      dispatch(
        loginSuccess({ ...userState, onboarding: res.data?.data?.onboarding })
      );
      dispatch(setAlert(true, "success", res.data?.message));
      return res.data?.status;
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const validateBvnAction =
  (data: any) => async (dispatch: any, getState: any) => {
    try {
      const userState = getState()?.userSlice?.user;

      const res = await postRequest({
        url: API_URLS?.bvnValidation,
        data,
        params: null,
        formData: null,
      });

      dispatch(
        loginSuccess({
          ...userState,
          bvn: data.bvn,
          phone: res.data?.data?.phone,
        })
      );
      dispatch(setAlert(true, "success", res.data?.message));
      return res.data?.status;
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const confirmBvnAction =
  (data: any) => async (dispatch: any, getState: any) => {
    try {
      const userState = getState()?.userSlice?.user;

      const res = await postRequest({
        url: API_URLS?.bvnConfirmation,
        data,
        params: null,
        formData: null,
      });

      dispatch(
        loginSuccess({
          ...userState,
          bvn: null,
          phone: null,
          onboarding: 3,
        })
      );
      dispatch(setAlert(true, "success", res.data?.message));
      return res.data?.status;
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const createPinAction =
  (data: any) => async (dispatch: any, getState: any) => {
    try {
      const userState = getState()?.userSlice?.user;

      const res = await postRequest({
        url: API_URLS?.pinCreation,
        data,
        params: null,
        formData: null,
      });

      dispatch(
        loginSuccess({
          ...userState,
          onboarding: 4,
        })
      );
      dispatch(setAlert(true, "success", res.data?.message));
      return res.data?.status;
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const skipMoreInfoAction =
  () => async (dispatch: any, getState: any) => {
    try {
      const userState = getState()?.userSlice?.user;

      const res = await postRequest({
        url: API_URLS?.createAccount,
        data: null,
        params: null,
        formData: true,
      });

      dispatch(
        loginSuccess({
          ...userState,
          onboarding: 5,
        })
      );
      dispatch(setAlert(true, "success", res.data?.message));
      dispatch(createdAccountSuccess(res.data?.data?.account));
      return res.data?.status;
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const updateMoreInfoAction =
  (data: any) => async (dispatch: any, getState: any) => {
    try {
      const userState = getState()?.userSlice?.user;

      const res = await postRequest({
        url: API_URLS?.moreInfo,
        data,
        params: null,
        formData: true,
      });

      dispatch(
        loginSuccess({
          ...userState,
          onboarding: 5,
        })
      );
      dispatch(setAlert(true, "success", res.data?.message));
      dispatch(createdAccountSuccess(res.data?.data?.account));
      return res.data?.status;
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const getAccountsAction =
  (data?: any) => async (dispatch: any, getState: any) => {
    try {
      const userState = getState()?.userSlice?.user;

      const res = await getRequest({
        url: API_URLS?.accounts,
        params: null,
      });

      const currentActiveAcc = res.data?.data?.find(
        (x: any) => x?.account_number === data?.accountNumber
      );

      dispatch(
        loginSuccess({
          ...userState,
          all_accounts: res.data?.data,
          active_account: currentActiveAcc
            ? currentActiveAcc
            : res.data?.data?.[0],
        })
      );
      currentActiveAcc &&
        dispatch(setAlert(true, "success", "Refresh successful!"));
      return res.data?.data;
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const setActiveAccountAction =
  (data: any) => async (dispatch: any, getState: any) => {
    try {
      const userState = getState()?.userSlice?.user;

      dispatch(
        loginSuccess({
          ...userState,
          active_account: data,
        })
      );
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const sendGeneralStatementAction =
  (data: any) => async (dispatch: any) => {
    try {
      const res = await postRequest({
        url: API_URLS?.generalStatement,
        data,
        params: null,
        formData: null,
      });

      dispatch(setAlert(true, "success", res.data?.message));
      return res.data?.status;
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const getReferralsAction = () => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: API_URLS?.referrals,
      params: null,
    });
    return res.data?.data?.referrals;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const getProfileInformationAction = () => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: API_URLS?.profileInformation,
      params: null,
    });
    return res.data?.data;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const updateProfileInformationAction =
  (data: any) => async (dispatch: any) => {
    try {
      const res = await postRequest({
        url: API_URLS?.profileInformation,
        data,
        params: null,
        formData: null,
      });

      dispatch(setAlert(true, "success", res.data?.message));
      return res.data?.status;
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const getResidenceAction = () => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: API_URLS?.residenceAddress,
      params: null,
    });
    return res.data?.data;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const updateResidenceAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await postRequest({
      url: API_URLS?.residenceAddress,
      data,
      params: null,
      formData: null,
    });

    dispatch(setAlert(true, "success", res.data?.message));
    return res.data?.status;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const getNextOfKinAction = () => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: API_URLS?.nextOfKin,
      params: null,
    });
    return res.data?.data;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const updateNextOfKinAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await postRequest({
      url: API_URLS?.nextOfKin,
      data,
      params: null,
      formData: null,
    });

    dispatch(setAlert(true, "success", res.data?.message));
    return res.data?.status;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const getDocumentsAction = () => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: API_URLS?.documents,
      params: null,
    });
    return res.data?.data;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const updateDocumentsAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await postRequest({
      url: API_URLS?.documents,
      data,
      params: null,
      formData: true,
    });

    dispatch(setAlert(true, "success", res.data?.message));
    return res.data?.status;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const getBvnAction = () => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: API_URLS?.bvn,
      params: null,
    });
    return res.data?.data?.bvn;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const updateBvnAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await postRequest({
      url: API_URLS?.bvn,
      data,
      params: null,
      formData: null,
    });

    dispatch(setAlert(true, "success", res.data?.message));
    return res.data?.status;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const updateSecurityQuestionAction =
  (data: any) => async (dispatch: any) => {
    try {
      const res = await postRequest({
        url: API_URLS?.securityQuestion,
        data,
        params: null,
        formData: null,
      });

      dispatch(setAlert(true, "success", res.data?.message));
      return res.data?.status;
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const logoutUserAction = () => async (dispatch: any) => {
  try {
    const res = await postRequest({
      url: API_URLS?.logout,
      data: null,
      params: null,
      formData: null,
    });

    dispatch(setAlert(true, "success", res.data?.message));
  } catch (err) {
    const errorMessage = getRequestError(err);
    console.log("LOGOUT ERR: ", errorMessage);
  }

  dispatch(logoutSuccess());
};
