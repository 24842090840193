import ReactPaginate from "react-paginate";

type Props = {
  pageCount: number;
  changeData: (page: { selected: number }) => void;
};

const Pagination = ({ pageCount, changeData }: Props) => (
  <ReactPaginate
    previousLabel="Prev"
    nextLabel="Next"
    breakLabel="..."
    breakClassName="break-me"
    pageCount={pageCount}
    marginPagesDisplayed={2}
    pageRangeDisplayed={2}
    onPageChange={changeData}
    containerClassName="pagination"
    activeClassName="active"
  />
);

export default Pagination;
