import { Modal } from "reactstrap";

import Button from "../button";

import Icon from "../../assets/svg";

type Props = {
  isOpen: boolean;
  close: any;
};

const DoneModal = ({ isOpen, close }: Props) => {
  return (
    <Modal className="size_medium done_modal" isOpen={isOpen}>
      <div className="body">
        <Icon name="checkmarkOrange" />
        <p>Done</p>

        <Button text="Okay" className="btn_secondary" onClick={close} />
      </div>
    </Modal>
  );
};

export default DoneModal;
