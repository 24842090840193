import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../../components/button";

import Icon from "../../../assets/svg";

const CompleteSection = () => {
  const history = useHistory();

  const { createdAccount } = useSelector((state: any) => state.userSlice);

  return (
    <React.Fragment>
      <div className="form">
        <div className="form_inner onboarding_complete">
          <Icon name="checkmarkOrange" />
          <h5>Done!</h5>
          <h6>You account has been created successfully</h6>

          <div className="infos">
            <div className="item">
              <h6>Account Name</h6>
              <p>{createdAccount?.account_name}</p>
            </div>
            <div className="item">
              <h6>Account Number</h6>
              <p>{createdAccount?.account_number}</p>
            </div>
          </div>

          <div className="actions">
            <Button
              text="Proceed to Dashboard"
              onClick={() => history.push("/home")}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CompleteSection;
