import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";

import PageTitle from "../../components/page-title";
import TopBar from "../../components/top-bar";
import Loader from "../../components/loader";
import SendMoneyModal from "../../components/modal/send-money";
import ReceiveMoneyModal from "../../components/modal/receive-money";
import AirtimeDataModal from "../../components/modal/airtime-data";
import UtilityBillModal from "../../components/modal/utility-modal";
// import FundAccountModal from "../../components/modal/fund-account";
// import CardPaymentModal from "../../components/modal/card-payment";
import DoneModal from "../../components/modal/done-modal";

import Transactions from "./transactions";
// import AdBanners from "./ad-banners";
import AccountOverview from "./account-overview";
import Action from "./action";

import {
  getAccountsAction,
  setActiveAccountAction,
} from "../../redux/user/user-slice";
import {
  getTransactionsByAccountAction,
  getBanksAction,
  getBeneficiariesAction,
  getBillBeneficiariesAction,
  getBillProvidersAction,
  getNetworkProvidersAction,
} from "../../redux/home/home-slice";

import {
  sendMoney,
  // receiveMoney,
  airtimeData,
  utilityBill,
} from "../../assets/img";

const HomePage = () => {
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: any) => state.userSlice);
  const {
    banks,
    beneficiaries,
    billBeneficiaries,
    billProviders,
    networkProviders,
    transactions,
  } = useSelector((state: any) => state.homeSlice);

  const [isFetching, setIsFetching] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    limit: 25,
    account: {
      label: "",
      value: "",
    },
    range: { label: "Today", value: "today" },
  });
  // const [fundAccountModal, setFundAccountModal] = useState(false);
  // const [cardPaymentModal, setCardPaymentModal] = useState(false);
  const [sendMoneyModal, setSendMoneyModal] = useState(false);
  const [receiveMoneyModal, setReceiveMoneyModal] = useState(false);
  const [airtimeDataModal, setAirtimeDataModal] = useState(false);
  const [utilityBillModal, setUtilityBillModal] = useState(false);
  const [doneModal, setDoneModal] = useState(false);

  useEffect(() => {
    setIsFetching(true);

    dispatch(getAccountsAction());
    !banks && dispatch(getBanksAction());
    !beneficiaries && dispatch(getBeneficiariesAction());
    !billBeneficiaries && dispatch(getBillBeneficiariesAction());
    !billProviders && dispatch(getBillProvidersAction());
    !networkProviders && dispatch(getNetworkProvidersAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params?.account?.value !== user?.active_account?.account_number) {
      setIsFetching(true);

      var account: any = null;
      if (params?.account?.value) {
        account = user?.all_accounts?.filter(
          (x: any) => x?.account_number === params?.account?.value
        )?.[0];
      } else {
        account = user?.all_accounts?.[0];
      }

      if (account) {
        dispatch(setActiveAccountAction(account)).then(() => {
          setParams({
            ...params,
            account: {
              label: `${account?.account_type} - ${account?.account_number}`,
              value: account?.account_number,
            },
          });

          getAccountTransactions();
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, user]);

  const getAccountTransactions = () => {
    setIsFetching(true);

    dispatch(
      getTransactionsByAccountAction({
        accountNumber: user?.active_account?.account_number,
        dateFilter: params?.range?.value,
      })
    ).then(() => {
      setIsFetching(false);
    });
  };

  useEffect(() => {
    if (params?.range?.value && params?.account?.value) {
      getAccountTransactions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.range?.value]);

  const refreshAccountInfo = async () => {
    setIsRefreshing(true);
    await dispatch(
      getAccountsAction({ accountNumber: user?.active_account?.account_number })
    );

    setIsRefreshing(false);
  };

  var accountList: {
    label: string;
    value: string;
  }[] = [];
  accountList = user?.all_accounts?.map((x: any) => ({
    label: `${x?.account_type} - ${x?.account_number}`,
    value: x?.account_number,
  }));

  const actionList: {
    name: string;
    image: any;
    title: string;
    description: string;
    onClick: (name: string) => void;
  }[] = [
    {
      name: "sendMoney",
      image: sendMoney,
      title: "Send Money",
      description: "Inter and Intra Bank Transfer.",
      onClick: () => setSendMoneyModal(true),
    },
    // {
    //   name: "receiveMoney",
    //   image: receiveMoney,
    //   title: "Receive Money",
    //   description: "Receive local and International Payments",
    //   onClick: () => setReceiveMoneyModal(true),
    // },
    {
      name: "airtimeData",
      image: airtimeData,
      title: "Airtime & Data",
      description: "",
      onClick: () => setAirtimeDataModal(true),
    },
    {
      name: "utilityBill",
      image: utilityBill,
      title: "Utility Bills",
      description: "",
      onClick: () => setUtilityBillModal(true),
    },
  ];

  const transactionsData = transactions?.map((x: any) => ({
    name: x?.narration,
    last_updated_at: x?.transaction_date,
    amount: parseInt(x?.amount) / 100,
    type: x?.record_type?.toLowerCase(),
  }));

  return (
    <React.Fragment>
      <PageTitle title="Home" />
      <TopBar titles={["Home"]} />

      {isFetching ? (
        <Loader />
      ) : (
        <div className="home_page_container">
          <div className="overview_actions">
            <AccountOverview
              params={params}
              setParams={setParams}
              accountList={accountList}
              activeAccount={user?.active_account}
              refreshAccount={refreshAccountInfo}
              isRefreshing={isRefreshing}
            />
            {actionList?.map((item, i) => (
              <Action
                key={i}
                name={item?.name}
                image={item?.image}
                title={item?.title}
                description={item?.description}
                onClick={item?.onClick}
              />
            ))}
          </div>

          <div className="transactions_adbanner">
            <Transactions
              params={params}
              setParams={setParams}
              isFetching={isFetching}
              transactions={transactionsData}
            />
            {/* <AdBanners /> */}
          </div>
        </div>
      )}

      <SendMoneyModal
        isOpen={sendMoneyModal}
        close={() => setSendMoneyModal(false)}
        onSuccess={() => {
          setSendMoneyModal(false);
          setDoneModal(true);
        }}
      />

      <ReceiveMoneyModal
        isOpen={receiveMoneyModal}
        close={() => setReceiveMoneyModal(false)}
        onSuccess={() => {
          setReceiveMoneyModal(false);
          setDoneModal(true);
        }}
      />

      <AirtimeDataModal
        isOpen={airtimeDataModal}
        close={() => setAirtimeDataModal(false)}
        onSuccess={() => {
          setAirtimeDataModal(false);
          setDoneModal(true);
        }}
      />

      <UtilityBillModal
        isOpen={utilityBillModal}
        close={() => setUtilityBillModal(false)}
        onSuccess={() => {
          setUtilityBillModal(false);
          setDoneModal(true);
        }}
      />

      {/* <FundAccountModal
        isOpen={fundAccountModal}
        close={() => setFundAccountModal(false)}
        toggleCardPayment={() => {
          setCardPaymentModal(true);
          setFundAccountModal(false);
        }}
      /> */}

      {/* <CardPaymentModal
        isOpen={cardPaymentModal}
        close={() => {
          setCardPaymentModal(false);
          setFundAccountModal(true);
        }}
        onSuccess={() => {
          setCardPaymentModal(false);
          setDoneModal(true);
        }}
      /> */}

      <DoneModal isOpen={doneModal} close={() => setDoneModal(false)} />
    </React.Fragment>
  );
};

export default HomePage;
