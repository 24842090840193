export const Question = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.5" cy="10.5" r="10.5" fill="#FF5001" />
    <path
      d="M10.402 12.704C10.318 12.704 10.248 12.6807 10.192 12.634C10.136 12.578 10.108 12.5033 10.108 12.41C10.108 12.3167 10.108 12.2233 10.108 12.13C10.108 12.0367 10.108 11.9433 10.108 11.85C10.1267 11.4487 10.2153 11.094 10.374 10.786C10.5327 10.478 10.724 10.198 10.948 9.946C11.1813 9.694 11.4147 9.456 11.648 9.232C11.8813 8.99867 12.0773 8.76533 12.236 8.532C12.404 8.29867 12.5067 8.05133 12.544 7.79C12.5813 7.454 12.516 7.174 12.348 6.95C12.18 6.71667 11.9513 6.53933 11.662 6.418C11.3727 6.29667 11.074 6.236 10.766 6.236C10.2713 6.236 9.84667 6.36667 9.492 6.628C9.14667 6.88 8.918 7.30933 8.806 7.916C8.778 8.028 8.73133 8.10733 8.666 8.154C8.60067 8.20067 8.526 8.224 8.442 8.224H7.77C7.686 8.224 7.61133 8.196 7.546 8.14C7.49 8.084 7.462 8.00933 7.462 7.916C7.47133 7.53333 7.55533 7.16933 7.714 6.824C7.87267 6.47867 8.09667 6.17533 8.386 5.914C8.68467 5.65267 9.03467 5.44733 9.436 5.298C9.84667 5.13933 10.304 5.06 10.808 5.06C11.3773 5.06 11.8627 5.13933 12.264 5.298C12.6747 5.44733 13.0013 5.65267 13.244 5.914C13.496 6.166 13.678 6.446 13.79 6.754C13.902 7.062 13.9487 7.37 13.93 7.678C13.902 8.05133 13.8087 8.38733 13.65 8.686C13.4913 8.97533 13.3 9.246 13.076 9.498C12.852 9.74067 12.6233 9.98333 12.39 10.226C12.166 10.4593 11.97 10.7067 11.802 10.968C11.6433 11.22 11.5547 11.4953 11.536 11.794C11.5267 11.8967 11.5173 11.9993 11.508 12.102C11.508 12.1953 11.508 12.2887 11.508 12.382C11.4893 12.4847 11.452 12.564 11.396 12.62C11.34 12.676 11.2607 12.704 11.158 12.704H10.402ZM10.29 15C10.1967 15 10.1173 14.972 10.052 14.916C9.996 14.8507 9.968 14.7713 9.968 14.678V13.824C9.968 13.7307 9.996 13.656 10.052 13.6C10.1173 13.5347 10.1967 13.502 10.29 13.502H11.2C11.3027 13.502 11.382 13.5347 11.438 13.6C11.5033 13.656 11.536 13.7307 11.536 13.824V14.678C11.536 14.7713 11.5033 14.8507 11.438 14.916C11.382 14.972 11.3027 15 11.2 15H10.29Z"
      fill="white"
    />
  </svg>
);
