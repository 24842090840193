import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../components/button";
import Logo from "../../components/logo";

import Icon from "../../assets/svg";

const ResetCompletePage = () => {
  const history = useHistory();

  const { user } = useSelector((state: any) => state.userSlice);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  if (user?.onboarding === 5) return <>{window.location.assign("/home")}</>;

  return (
    <React.Fragment>
      <div className="sign_in_page">
        <div className="form_container">
          <Logo />

          <div className="form">
            <div className="form_inner onboarding_complete">
              <Icon name="checkmarkOrange" />
              <h5>Done!</h5>
              <h6>Your password has been reseted successfully</h6>

              <div className="actions">
                <Button
                  text="Back to Login"
                  onClick={() => history.push("/")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetCompletePage;
