import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../redux";

import PageTitle from "../../components/page-title";
import TopBar from "../../components/top-bar";
import Loader from "../../components/loader";
// import Button from "../../components/button";

// import { formatAmount } from "../../utils/functions";

import { getReferralsAction } from "../../redux/user/user-slice";

// import Icon from "../../assets/svg";

const ReferralsPage = () => {
  const dispatch = useAppDispatch();

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [referrals, setReferrals] = useState<any | null>(null);

  useEffect(() => {
    setIsFetching(true);
    dispatch(getReferralsAction()).then((res) => {
      setReferrals(res);
      setIsFetching(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <PageTitle title="Referrals" />
      <TopBar titles={["Referrals"]} />

      {isFetching ? (
        <Loader />
      ) : (
        <div className="referrals_page_container">
          <div className="left">
            {/* <div className="info">
            <div>
              <p>My Rewards</p>
              <h6>{formatAmount(3000, 2)}</h6>
            </div>

            <Button text="Receive" type="submit" onClick={() => {}} />
          </div> */}

            <div className="records">
              <div className="item">
                <p>REFERRED</p>
                <p>{referrals?.length}</p>
              </div>

              {referrals?.length > 0 ? (
                referrals?.map((item: any, i: any) => (
                  <div key={i} className="item">
                    <p>{item?.name}</p>
                    <p>{item?.date}</p>
                  </div>
                ))
              ) : (
                <div className="item">Nothing to show</div>
              )}
            </div>
          </div>

          {/* <div className="right">
          <h6>My Referral link</h6>

          <div className="info">
            <p className="link">pecantrust.com/ref/04875/samjoseph</p>

            <div className="copy">
              <Icon name="copy" />
              <p>Copy</p>
            </div>
          </div>
        </div> */}
        </div>
      )}
    </React.Fragment>
  );
};

export default ReferralsPage;
