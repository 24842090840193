import HomePage from "./home";
// import CardsPage from "./cards";
import SettingsPage from "./settings";
// import AccountsPage from "./accounts";
import ReferralsPage from "./referrals";
import StatementPage from "./statement";
// import TalkToUs from "./talk-to-us";
import ProfilePage from "./profile";
// import LoansPage from "./loans";
// import InvestmentsPage from "./investments";
// import SavingsPage from "./savings";

type Props = {
  icon: string;
  label: string;
  to?: string | null;
  href?: string | null;
  component?: () => JSX.Element;
  unlist?: boolean;
}[];

const UserNavigations: Props = [
  {
    icon: "home",
    label: "Home",
    to: "/home",
    component: HomePage,
  },
  // {
  //   icon: "cards",
  //   label: "Cards",
  //   to: "/cards",
  //   component: CardsPage,
  // },
  // {
  //   icon: "savings",
  //   label: "Savings",
  //   to: "/savings",
  //   component: SavingsPage,
  // },
  // {
  //   icon: "investments",
  //   label: "Investments",
  //   to: "/investments",
  //   component: InvestmentsPage,
  // },
  // {
  //   icon: "accounts",
  //   label: "Accounts",
  //   to: "/accounts",
  //   component: AccountsPage,
  // },
  // {
  //   icon: "loans",
  //   label: "Loans",
  //   to: "/loans",
  //   component: LoansPage,
  // },
  {
    icon: "referrals",
    label: "Referrals",
    to: "/referrals",
    component: ReferralsPage,
  },
  {
    icon: "statements",
    label: "Statements",
    to: "/statements",
    component: StatementPage,
  },
  {
    icon: "settings",
    label: "Settings",
    to: "/settings",
    component: SettingsPage,
  },
  // {
  //   icon: "home",
  //   label: "Talk to us",
  //   to: "/talk-to-us",
  //   component: TalkToUs,
  // },
  {
    icon: "home",
    label: "Profile",
    to: "/profile",
    component: ProfilePage,
    unlist: true,
  },
  {
    icon: "logout",
    label: "Logout",
    to: null,
  },
];

export default UserNavigations;
