import { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { useForm, SubmitHandler } from "react-hook-form";

import SectionHeader from "../section-header";
import Button from "../button";
import FormInput from "../form-input";
import FormSelect from "../form-select";
import Loader from "../loader";
import EnterPin from "../enter-pin";

import Icon from "../../assets/svg";
import { westernUnion } from "../../assets/img";

import {
  numberFormValidation,
  textFormValidation,
  basicFormValidation,
} from "../../utils/functions";

type Props = {
  isOpen: boolean;
  close: any;
  onSuccess: any;
};

type MethodProps = {
  id: number;
  icon: string | undefined;
  name: string;
  desc: string;
};

type FormInputs = {
  senderCountry?: string;
  controlNumber?: number;
  secretAnswer?: string;
  creditAccount?: string;
};

const ReceiveMoneyModal = ({ isOpen, close, onSuccess }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });

  const [step, setStep] = useState(1);
  const [method, setMethod] = useState<MethodProps | null>({
    id: 1,
    icon: westernUnion,
    name: "Western Union",
    desc: "Receive Western Union Transfer",
  });
  const [pin, setPin] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePinChange = (value: string) => {
    setPin(value);
  };
  useEffect(() => {
    if (pin?.length === 4) {
      setIsSubmitting(true);

      let timer = setInterval(function () {
        console.log(pin);
        clearInterval(timer);

        // Make the API Call and, .then
        setIsSubmitting(false);
        onSuccess();
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  const submitFormOnSubmit: SubmitHandler<FormInputs> = (data) => {
    console.log(data, "data");

    // dispatch(authLoginUserAction(data));
    setStep(3);
  };

  const methods: MethodProps[] = [
    {
      id: 1,
      icon: westernUnion,
      name: "Western Union",
      desc: "Receive Western Union Transfer",
    },
  ];

  const countryList = [
    { label: "Nigeria", value: "nga" },
    { label: "United States", value: "usa" },
  ];

  const accountList: {
    label: string;
    value: string;
  }[] = [
    { label: "Standard 0048884763", value: "0048884763" },
    { label: "Wallet 0048787634", value: "0048787634" },
  ];

  return (
    <Modal className="send_money_modal" isOpen={isOpen}>
      <SectionHeader
        headText="Receive Money"
        close={() => {
          close();
          setStep(1);
        }}
        subText={
          step === 1
            ? "Select a method to receive money"
            : `From ${method?.name}`
        }
      />

      <div className={`body ${step > 1 && "no_padding"}`}>
        {step === 1 && (
          <div className="methods">
            {methods?.map((method, i) => (
              <div
                key={i}
                className="method"
                onClick={() => {
                  setMethod(method);
                  setStep(2);
                }}
              >
                <div className="left">
                  <img src={method?.icon} alt={method?.name} />
                  <div>
                    <p>{method?.name}</p>
                    <span>{method?.desc}</span>
                  </div>
                </div>

                <div className="right">
                  <Icon name="chevron" />
                </div>
              </div>
            ))}
          </div>
        )}

        {step === 2 && (
          <form className="form" onSubmit={handleSubmit(submitFormOnSubmit)}>
            <div className="form_inner">
              <FormSelect
                name="senderCountry"
                label="SENDER'S COUNTRY"
                type="default"
                defaultValue=""
                errorMessage={errors?.senderCountry?.message}
                selectOptions={countryList}
                selectRef={{
                  ...register("senderCountry", basicFormValidation(true)),
                }}
              />

              <FormInput
                label="MONEY TRANSFER COUNTROL NUMBER"
                type="number"
                readOnly={isSubmitting}
                errorMessage={errors?.controlNumber?.message}
                inputRef={{
                  ...register(
                    "controlNumber",
                    numberFormValidation(true, 2, 10)
                  ),
                }}
              />

              <FormInput
                label="WU SECRET ANSWER (OPTIONAL)"
                type="text"
                readOnly={isSubmitting}
                errorMessage={errors?.secretAnswer?.message}
                inputRef={{
                  ...register("secretAnswer", textFormValidation(false, 2)),
                }}
              />

              <FormSelect
                name="creditAccount"
                label="ACCOUNT TO CREDIT"
                type="default"
                defaultValue=""
                errorMessage={errors?.creditAccount?.message}
                selectOptions={accountList}
                selectRef={{
                  ...register("creditAccount", basicFormValidation(true)),
                }}
              />
            </div>

            <div className="action">
              <Button
                text="Next"
                type="submit"
                loading={isSubmitting}
                disabled={!isValid}
              />
            </div>
          </form>
        )}

        {step === 3 && (
          <div className="select_payment enter_pin">
            <div className="title">PIN</div>

            <EnterPin
              showText={true}
              pin={pin}
              onChange={handlePinChange}
              isDisabled={isSubmitting}
            />

            {isSubmitting && <Loader />}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ReceiveMoneyModal;
