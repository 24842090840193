export const Copy = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.74992 0.520874C3.30964 0.520874 0.520752 3.30977 0.520752 6.75004V14.4491C0.520752 14.8979 0.884521 15.2616 1.33325 15.2616C1.78198 15.2616 2.14575 14.8979 2.14575 14.4491V6.75004C2.14575 4.20723 4.20711 2.14587 6.74992 2.14587H14.3472C14.7959 2.14587 15.1597 1.78211 15.1597 1.33337C15.1597 0.884643 14.7959 0.520874 14.3472 0.520874H6.74992Z"
      fill="#FF5001"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.936 4.3594C13.425 3.967 9.82488 3.967 6.31385 4.3594C5.31364 4.47119 4.50987 5.25834 4.39198 6.26631C3.97557 9.82666 3.97557 13.4234 4.39198 16.9838C4.50987 17.9917 5.31364 18.7789 6.31385 18.8907C9.82489 19.2831 13.425 19.2831 16.936 18.8907C17.9362 18.7789 18.74 17.9917 18.8579 16.9838C19.2743 13.4234 19.2743 9.82666 18.8579 6.26631C18.74 5.25834 17.9362 4.47119 16.936 4.3594ZM6.49435 5.97435C9.88542 5.59535 13.3644 5.59535 16.7555 5.97435C17.0127 6.00309 17.2148 6.20644 17.2439 6.45508C17.6456 9.89001 17.6456 13.3601 17.2439 16.795C17.2148 17.0436 17.0127 17.247 16.7555 17.2757C13.3644 17.6547 9.88542 17.6547 6.49435 17.2757C6.23722 17.247 6.03506 17.0436 6.00598 16.795C5.60423 13.3601 5.60423 9.89001 6.00598 6.45508C6.03506 6.20644 6.23722 6.00309 6.49435 5.97435Z"
      fill="#FF5001"
    />
  </svg>
);
