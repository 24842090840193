import { Modal } from "reactstrap";

import SectionHeader from "../section-header";
import Button from "../button";

type Props = {
  isOpen: boolean;
  close: any;
  headText?: string;
  text: React.ReactNode;
  onClick: any;
  isSubmitting: boolean;
};

const ConfirmationModal = ({
  isOpen,
  close,
  headText,
  text,
  onClick,
  isSubmitting,
}: Props) => {
  return (
    <Modal className="size_medium confirmation_modal" isOpen={isOpen}>
      <SectionHeader
        headText={headText || "Confirmation"}
        headTextColored={true}
        close={close}
      />

      <div className="body">{text}</div>

      <div className="action">
        <Button
          text="Proceed"
          onClick={onClick}
          loading={isSubmitting}
          disabled={isSubmitting}
        />
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
