import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { Modal } from "reactstrap";
import { useForm, SubmitHandler } from "react-hook-form";

import SectionHeader from "../section-header";
import Button from "../button";
import FormInput from "../form-input";
import Loader from "../loader";
import EnterPin from "../enter-pin";

import { sendOtpAction } from "../../redux/user/user-slice";

import { postBillPaymentAction } from "../../redux/home/home-slice";

import Icon from "../../assets/svg";

import {
  numberFormValidation,
  formatAmount,
  basicFormValidation,
} from "../../utils/functions";

type Props = {
  isOpen: boolean;
  close: any;
  onSuccess: any;
};

type MethodProps = {
  id: number;
  biller: string;
  name: string;
  amount?: number;
  identifier: string;
};

type FormInputs = {
  search?: string;
  amount?: number;
  meterNumber?: number;
  identifier?: string;
};

const UtilityBillModal = ({ isOpen, close, onSuccess }: Props) => {
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();

  const { user } = useSelector((state: any) => state.userSlice);
  const { billProviders } = useSelector((state: any) => state.homeSlice);

  const allProviders = billProviders?.map((x: any) => ({
    id: x?.payment_id,
    biller: x?.biller,
    name: x?.payment_name,
    amount: x?.amount,
    identifier: x?.customer_identifier,
  }));

  const [step, setStep] = useState(1);
  const [providersOption, setProvidersOption] = useState<MethodProps[] | null>(
    allProviders
  );
  const [method, setMethod] = useState<MethodProps | null>(null);
  const [pin, setPin] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [purchaseData, setPurchaseData] = useState<any | null>(null);

  const watchSearch: any = watch("search", "");

  useEffect(() => {
    const filtered: any = allProviders?.filter((x: any) =>
      x?.biller?.toLowerCase()?.includes(watchSearch?.toLowerCase())
    );

    setProvidersOption(filtered || allProviders);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchSearch]);

  useEffect(() => {
    if (method?.amount) {
      setValue("amount", method?.amount);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method]);

  const handlePinChange = (value: string) => {
    setPin(value);
  };
  useEffect(() => {
    if (otp?.length === 4 && pin?.length === 4) {
      let timer = setInterval(function () {
        clearInterval(timer);

        makeBillPayment();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp, pin]);

  const makeBillPayment = async () => {
    setIsSubmitting(true);

    await dispatch(
      postBillPaymentAction({
        ...purchaseData,
        otp: otp,
        pin: pin,
      })
    ).then((res) => {
      if (res === true) {
        resetFormValues();
        onSuccess();
      }
    });
    setIsSubmitting(false);
  };

  const saveFormInfo: SubmitHandler<FormInputs> = (data) => {
    const payload = {
      customer_number: data?.identifier,
      account_number: user?.active_account?.account_number,
      payment_item_id: method?.id,
      amount: data?.amount,
      customer_name: user?.active_account?.account_name,
    };

    dispatch(sendOtpAction({ email: user?.email }));

    setPurchaseData(payload);
    setStep(3);
  };

  const resetFormValues = () => {
    reset();
    setStep(1);
    setPin("");
    setOtp("");
    setMethod(null);
  };

  return (
    <Modal className="send_money_modal" isOpen={isOpen}>
      <SectionHeader
        headText="Utility Bill"
        close={() => {
          close();
          resetFormValues();
        }}
        subText={
          step === 1
            ? "Select a merchant"
            : `For: ${method?.biller} > ${method?.name}`
        }
      />

      <div className={`body ${step > 1 && "no_padding"}`}>
        {step === 1 && (
          <>
            <FormInput
              type="text"
              placeholder="Search bill"
              readOnly={false}
              errorMessage={null}
              inputRef={{
                ...register("search", basicFormValidation(false)),
              }}
            />

            <div className="methods">
              {providersOption?.map((method, i) => (
                <div
                  key={i}
                  className="method"
                  onClick={() => {
                    setMethod(method);
                    setStep(2);
                  }}
                >
                  <div className="left">
                    <p>{method?.biller}</p>
                    <span>{method?.name}</span>
                  </div>

                  <div className="right">
                    <Icon name="chevron" />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        {step === 2 && (
          <form className="form" onSubmit={handleSubmit(saveFormInfo)}>
            <div className="form_inner">
              <FormInput
                label="AMOUNT"
                type="number"
                readOnly={method?.amount ? true : false}
                errorMessage={errors?.amount?.message}
                inputRef={{
                  ...register("amount", numberFormValidation(true, 3, 10)),
                }}
              />

              <FormInput
                label={method?.identifier?.toUpperCase()}
                type="text"
                readOnly={isSubmitting}
                errorMessage={errors?.identifier?.message}
                inputRef={{
                  ...register("identifier", basicFormValidation(true)),
                }}
              />
            </div>

            <div className="action">
              <Button
                text="Next"
                type="submit"
                loading={isSubmitting}
                disabled={!isValid}
              />
            </div>
          </form>
        )}

        {step === 3 && (
          <div className="select_payment enter_pin">
            <div className="transaction_summary">
              <p>{purchaseData?.customer_number}</p>
              <h6>{formatAmount(purchaseData?.amount, 0)}</h6>
            </div>

            <EnterPin
              showText={true}
              text="Enter the OTP sent to your email"
              pinLength={4}
              pin={otp}
              onChange={(value) => setOtp(value)}
              isDisabled={isSubmitting}
            />

            <EnterPin
              showText={true}
              pin={pin}
              onChange={handlePinChange}
              isDisabled={isSubmitting}
            />

            {isSubmitting && <Loader />}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UtilityBillModal;
