import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";

import FormInput from "../../components/form-input";
import Button from "../../components/button";
import Logo from "../../components/logo";

import { useAppDispatch } from "../../redux";
import { authLoginUserAction } from "../../redux/user/user-slice";

import {
  emailFormValidation,
  passwordFormValidation,
} from "../../utils/functions";

type FormInputs = {
  email: string;
  password: string;
};

const SignInPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { user } = useSelector((state: any) => state.userSlice);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const submitFormOnSubmit: SubmitHandler<FormInputs> = (data) => {
    setIsSubmitting(true);

    dispatch(authLoginUserAction(data)).then(() => setIsSubmitting(false));
  };

  if (user?.account_created && !isSubmitting)
    return <>{window.location.assign("/home")}</>;

  if (user?.onboarding < 4 && !user?.account_created && !isSubmitting)
    return <>{window.location.assign("/onboarding")}</>;

  return (
    <React.Fragment>
      <div className="sign_in_page">
        <div className="form_container">
          <Logo />

          <form className="form" onSubmit={handleSubmit(submitFormOnSubmit)}>
            <div className="form_inner">
              <h5>LOGIN</h5>

              <FormInput
                label="EMAIL"
                type="email"
                readOnly={isSubmitting}
                errorMessage={errors?.email?.message}
                inputRef={{
                  ...register("email", emailFormValidation(true)),
                }}
              />
              <FormInput
                label="PASSWORD"
                type="password"
                readOnly={isSubmitting}
                errorMessage={errors?.password?.message}
                inputRef={{
                  ...register("password", passwordFormValidation(true)),
                }}
              />
              <p className="forgot_password">
                <Link to="/forgot-password">FORGOT PASSWORD?</Link>
              </p>

              <div className="actions">
                <Button
                  text="Login"
                  type="submit"
                  loading={isSubmitting}
                  disabled={!isValid}
                />
                <p className="or">Or</p>
                <Button
                  text="Register"
                  className="btn_secondary"
                  onClick={(e: any) => {
                    e.preventDefault();

                    history.push("/register");
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignInPage;
