import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { Modal } from "reactstrap";
import { useForm, SubmitHandler } from "react-hook-form";

import SectionHeader from "../section-header";
import Button from "../button";
import FormInput from "../form-input";
import FormSelect from "../form-select";
import Loader from "../loader";
import EnterPin from "../enter-pin";

import Icon from "../../assets/svg";
import { pecantrustLogo } from "../../assets/img";

import { sendOtpAction } from "../../redux/user/user-slice";

import {
  validateInterBankAccountAction,
  validateIntraBankAccountAction,
  postInterBankTransferAction,
  postIntraBankTransferAction,
} from "../../redux/home/home-slice";

import {
  numberFormValidation,
  textFormValidation,
  basicFormValidation,
  formatAmount,
} from "../../utils/functions";

type Props = {
  isOpen: boolean;
  close: any;
  onSuccess: any;
};

type MethodProps = {
  id: number;
  icon: string | null;
  name: string;
};

type FormInputs = {
  amount?: number;
  accountNumber?: string;
  purpose?: string;
  saveBeneficiary?: boolean;
  beneficiary?: string;
  bank?: string;
};

const SendMoneyModal = ({ isOpen, close, onSuccess }: Props) => {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();

  const { user } = useSelector((state: any) => state.userSlice);
  const { banks, beneficiaries } = useSelector((state: any) => state.homeSlice);

  const [step, setStep] = useState(1);
  const [method, setMethod] = useState<MethodProps | null>({
    id: 1,
    icon: null,
    name: "PecanTrust account",
  });
  const [bank, setBank] = useState<{ label: string; value: string } | null>(
    null
  );
  const [beneficiary, setBeneficiary] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [pin, setPin] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [isValidating, setIsValidating] = useState(false);
  const [validatedAccName, setValidatedAccName] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transferData, setTransferData] = useState<any | null>(null);

  const watchAccountNumber = watch("accountNumber", "");

  const validateAccountInfo = async () => {
    clearErrors("accountNumber");
    setIsValidating(true);

    const bankValue = bank?.value || beneficiary?.value;

    const funcToUse = bankValue
      ? validateInterBankAccountAction({
          account_number: watchAccountNumber,
          bank_code: bankValue,
        })
      : validateIntraBankAccountAction({
          account_number: watchAccountNumber,
        });

    await dispatch(funcToUse).then((res: any) => {
      if (res?.status === true) {
        setValidatedAccName(res?.name);
      } else {
        setValidatedAccName(null);
        setError("accountNumber", {
          type: "manual",
          message: "Unable to validate account!",
        });
      }
    });

    setIsValidating(false);
  };
  useEffect(() => {
    if (
      (method?.id === 1 || bank?.value) &&
      watchAccountNumber?.length === 10
    ) {
      validateAccountInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAccountNumber]);

  const handlePinChange = (value: string) => {
    setPin(value);
  };
  useEffect(() => {
    if (otp?.length === 4 && pin?.length === 4) {
      let timer = setInterval(function () {
        clearInterval(timer);

        makeBankTransfer();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp, pin]);

  const makeBankTransfer = async () => {
    setIsSubmitting(true);

    const funcToUse = transferData?.bank_code
      ? postInterBankTransferAction
      : postIntraBankTransferAction;

    await dispatch(
      funcToUse({
        ...transferData,
        otp: otp,
        pin: pin,
      })
    ).then((res) => {
      if (res === true) {
        onSuccess();
      }
    });
    setIsSubmitting(false);
  };

  const saveFormInfo: SubmitHandler<FormInputs> = (data) => {
    const payload: any = {
      pin: pin,
      amount: data?.amount,
      payer_account_number: user?.active_account?.account_number,
      save_beneficiary: data?.saveBeneficiary,
      account_number: data?.accountNumber,
      bank_code: bank?.value || "",
      narration: data?.purpose,
    };

    if (method?.id === 1) {
      delete payload.bank_code;
    }

    dispatch(sendOtpAction({ email: user?.email }));

    setTransferData(payload);
    setStep(3);
  };

  const methods: MethodProps[] = [
    { id: 1, icon: null, name: "PecanTrust account" },
    { id: 2, icon: "beneficiary", name: "saved beneficiary" },
    { id: 3, icon: "bank", name: "other bank account" },
  ];

  const bankOptions = banks?.map((x: any) => ({
    label: x?.name,
    value: x?.code,
  }));

  const beneficiaryOptions =
    beneficiaries?.map((x: any) => ({
      label: x?.name,
      value: x?.code,
    })) || [];

  const accountBalance = Number(
    user?.active_account?.account_balance?.toString()?.replace(",", "")
  );

  return (
    <Modal className="send_money_modal" isOpen={isOpen}>
      <SectionHeader
        headText="Send Money"
        close={() => {
          close();
          setStep(1);
        }}
        subText={
          step === 1 ? "Select a method to send money" : `To ${method?.name}`
        }
      />

      <div className={`body ${step > 1 && "no_padding"}`}>
        {step === 1 && (
          <div className="methods">
            {methods?.map((method, i) => (
              <div
                key={i}
                className="method"
                onClick={() => {
                  setMethod(method);
                  setStep(2);
                }}
              >
                <div className="left">
                  {method?.icon ? (
                    <Icon name={method?.icon} />
                  ) : (
                    <img src={pecantrustLogo} alt={method?.name} />
                  )}
                  <p>{method?.name}</p>
                </div>

                <div className="right">
                  <Icon name="chevron" />
                </div>
              </div>
            ))}
          </div>
        )}

        {step === 2 && (
          <form className="form" onSubmit={handleSubmit(saveFormInfo)}>
            {method?.id === 1 && (
              <div className="form_inner">
                <FormInput
                  label="AMOUNT"
                  type="number"
                  readOnly={isSubmitting}
                  errorMessage={errors?.amount?.message}
                  inputRef={{
                    ...register(
                      "amount",
                      numberFormValidation(true, 2, 10, 50, accountBalance)
                    ),
                  }}
                />

                <FormInput
                  label="ACCOUNT NUMBER"
                  type="number"
                  readOnly={isSubmitting}
                  errorMessage={errors?.accountNumber?.message}
                  inputRef={{
                    ...register(
                      "accountNumber",
                      numberFormValidation(true, 10, 10)
                    ),
                  }}
                />
                <p
                  className={`account_name${isValidating ? ` validating` : ``}`}
                >
                  {isValidating
                    ? "Validating recipient account..."
                    : validatedAccName
                    ? validatedAccName
                    : "."}
                </p>

                <FormInput
                  label="PURPOSE"
                  type="text"
                  readOnly={isSubmitting}
                  errorMessage={errors?.purpose?.message}
                  inputRef={{
                    ...register("purpose", textFormValidation(true, 2)),
                  }}
                />

                <FormInput
                  label="Save Beneficiary?"
                  type="checkbox"
                  readOnly={isSubmitting}
                  errorMessage={errors?.saveBeneficiary?.message}
                  inputRef={{
                    ...register("saveBeneficiary", basicFormValidation(false)),
                  }}
                />
              </div>
            )}

            {method?.id === 2 && (
              <div className="form_inner">
                <FormInput
                  label="AMOUNT"
                  type="number"
                  readOnly={isSubmitting}
                  errorMessage={errors?.amount?.message}
                  inputRef={{
                    ...register(
                      "amount",
                      numberFormValidation(true, 2, 10, 50, accountBalance)
                    ),
                  }}
                />

                <FormSelect
                  name="beneficiary"
                  label="BENEFICIARY"
                  type=""
                  errorMessage={errors?.beneficiary?.message}
                  options={beneficiaryOptions}
                  onChange={(value: any) => setBeneficiary(value)}
                  readOnly={isValidating || isSubmitting}
                />

                <FormInput
                  label="PURPOSE"
                  type="text"
                  readOnly={isSubmitting}
                  errorMessage={errors?.purpose?.message}
                  inputRef={{
                    ...register("purpose", textFormValidation(true, 2)),
                  }}
                />
              </div>
            )}

            {method?.id === 3 && (
              <div className="form_inner">
                <FormInput
                  label="AMOUNT"
                  type="number"
                  readOnly={isSubmitting}
                  errorMessage={errors?.amount?.message}
                  inputRef={{
                    ...register(
                      "amount",
                      numberFormValidation(true, 2, 10, 50, accountBalance)
                    ),
                  }}
                />

                <FormSelect
                  name="bank"
                  label="BANK"
                  type=""
                  errorMessage={errors?.bank?.message}
                  options={bankOptions}
                  onChange={(value: any) => setBank(value)}
                  readOnly={isValidating || isSubmitting}
                />

                <FormInput
                  label="ACCOUNT NUMBER"
                  type="number"
                  readOnly={isValidating || isSubmitting}
                  errorMessage={errors?.accountNumber?.message}
                  inputRef={{
                    ...register(
                      "accountNumber",
                      numberFormValidation(true, 10, 10)
                    ),
                  }}
                />
                <p
                  className={`account_name${isValidating ? ` validating` : ``}`}
                >
                  {isValidating
                    ? "Validating recipient account..."
                    : validatedAccName
                    ? validatedAccName
                    : "."}
                </p>

                <FormInput
                  label="PURPOSE"
                  type="text"
                  readOnly={isSubmitting}
                  errorMessage={errors?.purpose?.message}
                  inputRef={{
                    ...register("purpose", textFormValidation(true, 2)),
                  }}
                />

                <FormInput
                  label="Save Beneficiary?"
                  type="checkbox"
                  readOnly={isSubmitting}
                  errorMessage={errors?.saveBeneficiary?.message}
                  inputRef={{
                    ...register("saveBeneficiary", basicFormValidation(false)),
                  }}
                />
              </div>
            )}

            <div className="action">
              <Button
                text="Next"
                type="submit"
                loading={isSubmitting}
                disabled={isValidating || !isValid}
              />
            </div>
          </form>
        )}

        {step === 3 && (
          <div className="select_payment enter_pin">
            <div className="transaction_summary">
              <h5>{validatedAccName}</h5>
              <p>
                {transferData?.account_number}, {bank?.label}
              </p>
              <h6>{formatAmount(transferData?.amount, 0)}</h6>
            </div>

            <EnterPin
              showText={true}
              text="Enter the OTP sent to your email"
              pinLength={4}
              pin={otp}
              onChange={(value) => setOtp(value)}
              isDisabled={isSubmitting}
            />

            <EnterPin
              showText={true}
              pin={pin}
              onChange={handlePinChange}
              isDisabled={isSubmitting}
            />

            {isSubmitting && <Loader />}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SendMoneyModal;
