import React from "react";

import { Accounts } from "./accounts";
import { Add } from "./add";
import { Bank } from "./bank";
import { Beneficiary } from "./beneficiary";
import { Calendar } from "./calendar";
import { Call } from "./call";
import { Card } from "./card";
import { Cards } from "./cards";
import { CheckmarkOrange } from "./checkmark-orange";
import { Checkmark } from "./checkmark";
import { ChevronSquare } from "./chevron-square";
import { Chevron } from "./chevron";
import { Close } from "./close";
import { Copy } from "./copy";
import { Delete } from "./delete";
import { Error } from "./error";
import { EyeSlash } from "./eye-slash";
import { Eye } from "./eye";
import { Home } from "./home";
import { InfoCircle } from "./info-circle";
import { Investments } from "./investments";
import { Loans } from "./loans";
import { Lock } from "./lock";
import { Logout } from "./logout";
import { MoreDots } from "./more-dots";
import { Question } from "./question";
import { Referrals } from "./referrals";
import { Refresh } from "./refresh";
import { Savings } from "./savings";
import { Search } from "./search";
import { Settings } from "./settings";
import { Statements } from "./statements";
import { TimeCircle } from "./time-circle";

const Icon = ({ name }: { name: string }) => {
  switch (name) {
    case "accounts":
      return <Accounts />;
    case "add":
      return <Add />;
    case "bank":
      return <Bank />;
    case "beneficiary":
      return <Beneficiary />;
    case "calendar":
      return <Calendar />;
    case "call":
      return <Call />;
    case "card":
      return <Card />;
    case "cards":
      return <Cards />;
    case "checkmarkOrange":
      return <CheckmarkOrange />;
    case "checkmark":
      return <Checkmark />;
    case "chevronSquare":
      return <ChevronSquare />;
    case "chevron":
      return <Chevron />;
    case "close":
      return <Close />;
    case "copy":
      return <Copy />;
    case "delete":
      return <Delete />;
    case "error":
      return <Error />;
    case "eyeSlash":
      return <EyeSlash />;
    case "eye":
      return <Eye />;
    case "home":
      return <Home />;
    case "infoCircle":
      return <InfoCircle />;
    case "investments":
      return <Investments />;
    case "loans":
      return <Loans />;
    case "lock":
      return <Lock />;
    case "logout":
      return <Logout />;
    case "moreDots":
      return <MoreDots />;
    case "question":
      return <Question />;
    case "referrals":
      return <Referrals />;
    case "refresh":
      return <Refresh />;
    case "savings":
      return <Savings />;
    case "search":
      return <Search />;
    case "settings":
      return <Settings />;
    case "statements":
      return <Statements />;
    case "timeCircle":
      return <TimeCircle />;
    default:
      return <Add />;
  }
};

export default Icon;
