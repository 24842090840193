import { useState, useEffect } from "react";
import { useAppDispatch } from "../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from "../../components/button";
import DatePickerComponent from "../../components/datepicker";
import FormInput from "../../components/form-input";
import FormSelect from "../../components/form-select";
import Loader from "../../components/loader";

import {
  getProfileInformationAction,
  updateProfileInformationAction,
} from "../../redux/user/user-slice";

import {
  nameFormValidation,
  textFormValidation,
  basicFormValidation,
} from "../../utils/functions";

type FormInputs = {
  first_name: string;
  last_name: string;
  other_name: string;
  gender: string;
  nationality: string;
  mothers_maiden_name: string;
  occupation: string;
  employer_name: string;
  employer_phone: string;
  employer_address: string;
};

const ProfileInformation = () => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const [isFetching, setIsFetching] = useState(false);
  const [profileInfo, setProfileInfo] = useState<any | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    if (!profileInfo) {
      setIsFetching(true);

      dispatch(getProfileInformationAction()).then((res) => {
        setProfileInfo(res);
        setIsFetching(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profileInfo) {
      setValue("first_name", profileInfo?.first_name);
      setValue("last_name", profileInfo?.last_name);
      setValue("other_name", profileInfo?.other_name);
      setValue("gender", profileInfo?.gender);
      setSelectedDate(profileInfo?.date_of_birth);
      setValue("nationality", profileInfo?.nationality);
      setValue("mothers_maiden_name", profileInfo?.mothers_maiden_name);
      setValue("occupation", profileInfo?.occupation);
      setValue("employer_address", profileInfo?.employer_address);
      setValue("employer_name", profileInfo?.employer_name);
      setValue("employer_phone", profileInfo?.employer_phone);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileInfo]);

  const submitFormOnSubmit: SubmitHandler<FormInputs> = async (data) => {
    setIsSubmitting(true);

    await dispatch(
      updateProfileInformationAction({
        ...data,
      })
    );
    setIsSubmitting(false);
  };

  const datePickerDisplayValue = (date: Date) => {
    const moment = require("moment");
    return `${moment(date)?.format("DD MMM, yyyy")}`;
  };

  const genderOptions = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
  ];

  return (
    <div className="profile_section">
      {isFetching ? (
        <Loader />
      ) : (
        <form className="form" onSubmit={handleSubmit(submitFormOnSubmit)}>
          <FormInput
            label="FIRST NAME"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.first_name?.message}
            inputRef={{
              ...register("first_name", nameFormValidation(true)),
            }}
          />
          <FormInput
            label="LAST NAME"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.last_name?.message}
            inputRef={{
              ...register("last_name", nameFormValidation(true)),
            }}
          />
          <FormInput
            label="OTHER NAME"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.other_name?.message}
            inputRef={{
              ...register("other_name", nameFormValidation(true)),
            }}
          />
          <FormSelect
            label="GENDER"
            name="gender"
            type="default"
            defaultValue=""
            errorMessage={errors?.gender?.message}
            selectOptions={genderOptions}
            selectRef={{
              ...register("gender", basicFormValidation(true)),
            }}
          />

          <div className="form_group_container">
            <div className="form-group">
              <label>DATE OF BIRTH</label>

              <DatePickerComponent
                onChange={(date: Date) => setSelectedDate(date)}
                value={datePickerDisplayValue(selectedDate)}
                range={false}
                showYearDropdown={true}
              />
            </div>
          </div>

          <FormInput
            label="NATIONALITY"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.nationality?.message}
            inputRef={{
              ...register("nationality", textFormValidation(true, 2)),
            }}
          />
          <FormInput
            label="MOTHER'S MAIDEN NAME"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.mothers_maiden_name?.message}
            inputRef={{
              ...register("mothers_maiden_name", nameFormValidation(true)),
            }}
          />
          <FormInput
            label="OCCUPATION"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.occupation?.message}
            inputRef={{
              ...register("occupation", textFormValidation(true, 2)),
            }}
          />
          <FormInput
            label="EMPLOYER'S NAME"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.employer_name?.message}
            inputRef={{
              ...register("employer_name", textFormValidation(true, 3)),
            }}
          />
          <FormInput
            label="EMPLOYER'S PHONE"
            type="number"
            readOnly={isSubmitting}
            errorMessage={errors?.employer_phone?.message}
            inputRef={{
              ...register("employer_phone", textFormValidation(true, 10)),
            }}
          />
          <FormInput
            label="EMPLOYER'S ADDRESS"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.employer_address?.message}
            inputRef={{
              ...register("employer_address", textFormValidation(true, 5)),
            }}
          />

          <div className="action">
            <Button
              text="Save Changes"
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default ProfileInformation;
