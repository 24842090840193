export const Card = () => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M6.05859 24.4804L24.4815 6.05753"
      stroke="#FF5001"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.4"
      d="M17.1143 28.1801L18.9643 26.3301"
      stroke="#FF5001"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.4"
      d="M21.2637 24.0326L24.9483 20.3481"
      stroke="#FF5001"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.55163 15.7851L15.7883 5.54847C19.0566 2.28014 20.6908 2.26472 23.9283 5.50222L31.4979 13.0718C34.7354 16.3093 34.72 17.9435 31.4516 21.2118L21.215 31.4485C17.9466 34.7168 16.3125 34.7322 13.075 31.4947L5.50538 23.9251C2.26788 20.6876 2.26788 19.0689 5.55163 15.7851Z"
      stroke="#FF5001"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.08301 33.9144H33.9163"
      stroke="#FF5001"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
