import axios from "axios";

import { APP_USER } from "./constants";

// Local storage operations
export const useSessionStorage = {
  set: (key: string, data: any) => {
    let stringifiedData = JSON.stringify(data);
    sessionStorage.setItem(key, stringifiedData);
  },

  get: (key: string) => {
    const data: any = JSON.parse(sessionStorage.getItem(key)!);

    if (!data) {
      return null;
    }
    return data;
  },

  remove: (key: string) => {
    sessionStorage.removeItem(key);
  },

  clear: () => {
    sessionStorage.clear();
  },
};

export const getUserDetails = () => {
  const user = useSessionStorage.get(APP_USER);

  return user ? user : null;
};

export const getRequestError = (error: any) => {
  const { response } = error;
  console.log(response);

  if (response && response?.status === 405) {
    logout();
  } else if (response && response?.data?.errors) {
    const errorMessage = Object.values(response?.data?.errors)[0] || "";
    return errorMessage;
  } else if (response && response?.data?.message) {
    return response?.data?.message;
  }
  return "There might be a problem with your internet connection. Please check and try again.";
};

export const refreshToken = async () => {
  const currentUser = getUserDetails();
  const headers = {
    "x-secret": currentUser?.secret,
  };
  const data = { refresh: currentUser?.refreshToken };

  const res = await axios
    .post(`${process.env.REACT_APP_API_BASE_URL}auth/refresh`, data, {
      headers: headers,
    })
    .then((res) => {
      const status = res?.data?.statusCode;
      if (status === 200) {
        useSessionStorage.set(APP_USER, {
          ...currentUser,
          ...res?.data?.data,
        });
        return res?.data?.data;
      }
    })
    .catch((err) => {
      const errorMessage = getRequestError(err);
      console.log(errorMessage);
    });
  return res;
};

export const logout = () => {
  useSessionStorage.clear();
  window.scrollTo(0, 0);
  window.location.assign("/");
};

export const formatDateInWords = (date: string) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const current_date = new Date(date);
  const month_value = current_date.getMonth();
  const day_value = current_date.getDate();
  const year = current_date.getUTCFullYear();
  let hour: any = current_date.getHours();
  let mins: any = current_date.getMinutes();

  if (hour < 10) hour = `0${hour}`;
  if (mins < 10) mins = `0${mins}`;

  return date
    ? `${day_value} ${months[month_value]}, ${year} | ${hour}:${mins}`
    : "";
};

export const formatAmount = (amount: number, decimals: number) => {
  var amountToUse = Number(amount?.toString()?.replace(",", ""));
  
  return amountToUse
    ? `₦` +
        Number(amountToUse)
          .toFixed(decimals || 2)
          .replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
          })
    : `₦--`;
};

export const formatNumber = (n: number, decimals: number) => {
  var amountToUse = Number(n?.toString()?.replace(",", ""));

  return (
    amountToUse &&
    Number(amountToUse)
      .toFixed(decimals || 0)
      .replace(/./g, function (c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
      })
  );
};

export const firstLetter = (letter: string) => {
  var str = letter;
  var res = str?.substring(0, 1);
  return res;
};

export const getInitials = (letter: string) => {
  var words = letter?.split(' ')?.slice(0,2)?.join(' ');
  var matches = words?.match(/\b(\w)/g);
  var initials = matches?.join('');
  return initials;
};

export const statusStyling = (status: string) => {
  switch (status) {
    case "active":
      return "active";
    case "pending":
      return "warning";
    case "failed":
      return "error";
    default:
      return "success";
  }
};

// Form Validations
export const emailFormValidation = (required: boolean) => {
  return {
    required: required ? "Email address is required" : false,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Invalid email address",
    },
  };
};
export const nameFormValidation = (required: boolean) => {
  return {
    required: required ? "This field is required" : false,
    pattern: {
      value: /^[A-Za-z]+$/,
      message: "Invalid name",
    },
    minLength: {
      value: 2,
      message: "Must be more than 1 character",
    },
  };
};
export const textFormValidation = (required: boolean, minLength?: number) => {
  return {
    required: required ? "This field is required" : false,
    minLength: minLength
      ? {
          value: minLength + 1,
          message: `Must be more than ${minLength} character(s)`,
        }
      : {
          value: 2,
          message: "Must be more than 1 character",
        },
  };
};
export const numberFormValidation = (
  required: boolean,
  minLength: number,
  maxLength: number,
  min?: number,
  max?: number
) => {
  return {
    required: required ? "This field is required" : false,
    minLength: {
      value: minLength,
      message: `Minimum characters is ${minLength}`,
    },
    maxLength: {
      value: maxLength,
      message: `Maximum characters is ${maxLength}`,
    },
    min: {
      value: min || 0,
      message: `Minimum value is ${min || 0}`,
    },
    max: {
      value: max || 10000000000000000000,
      message: `Maximum value is ${max || 10000000000000000000}`,
    },
  };
};
export const passwordFormValidation = (required: boolean) => {
  return {
    required: required ? "This field is required" : false,
    minLength: {
      value: 8,
      message: "Must be atleast 8 characters",
    },
  };
};
export const basicFormValidation = (required: boolean) => {
  return {
    required: required ? "This field is required" : false,
  };
};
