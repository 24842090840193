import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { ProtectedRoute } from "./components/protected-route";
import Dashbase from "./dashbase/dashbase";

import SignInPage from "./pages/auth/sign-in";
import RegisterPage from "./pages/auth/register";
import ForgotPasswordPage from "./pages/auth/forgot-password";
import OTPPage from "./pages/auth/otp";
import CreateNewPasswordPage from "./pages/auth/create-new-password";
import ResetCompletePage from "./pages/auth/reset-complete";
import OnboardingPage from "./pages/auth/onboarding/onboarding";

import Alert from "./components/alert";
import "react-datepicker/dist/react-datepicker.css";

const App = () => {
  const { alert } = useSelector((state: any) => state.componentsSlice);

  const Page = () => <div>Hello</div>;

  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={SignInPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <Route exact path="/otp-login" component={OTPPage} />
          <Route exact path="/new-password" component={CreateNewPasswordPage} />
          <Route exact path="/reset-complete" component={ResetCompletePage} />
          <Route exact path="/onboarding" component={OnboardingPage} />
          <Route exact path="/other" component={Page} />
          <ProtectedRoute component={Dashbase} />
        </Switch>
      </BrowserRouter>

      {/* Other components */}
      {alert?.show && (
        <Alert className={alert?.type} text={alert?.message} close />
      )}
    </React.Fragment>
  );
};

export default App;
