export const MoreDots = () => (
  <svg
    width="22"
    height="6"
    viewBox="0 0 22 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.0178 1.10723C21.9941 2.08354 21.9941 3.66646 21.0178 4.64277C20.0415 5.61908 18.4585 5.61908 17.4822 4.64277C16.5059 3.66646 16.5059 2.08354 17.4822 1.10723C18.4585 0.130923 20.0415 0.130923 21.0178 1.10723Z"
      fill="#061A40"
    />
    <path
      d="M12.6428 1.10723C13.6191 2.08354 13.6191 3.66646 12.6428 4.64277C11.6665 5.61908 10.0835 5.61908 9.10723 4.64277C8.13092 3.66646 8.13092 2.08354 9.10723 1.10723C10.0835 0.130923 11.6665 0.130923 12.6428 1.10723Z"
      fill="#061A40"
    />
    <path
      d="M4.26777 1.10723C5.24408 2.08354 5.24408 3.66646 4.26777 4.64277C3.29146 5.61908 1.70854 5.61908 0.732232 4.64277C-0.244077 3.66646 -0.244077 2.08354 0.732232 1.10723C1.70854 0.130923 3.29146 0.130923 4.26777 1.10723Z"
      fill="#061A40"
    />
  </svg>
);
