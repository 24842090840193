import { useState, useEffect } from "react";
import { useAppDispatch } from "../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from "../../components/button";
import FormInput from "../../components/form-input";
import FormSelect from "../../components/form-select";

import {
  getSecurityQuestionsAction,
  updateSecurityQuestionAction,
} from "../../redux/user/user-slice";

import { basicFormValidation, textFormValidation } from "../../utils/functions";
import Loader from "../../components/loader";

type Props = {
  onSuccess: () => void;
};

type FormInputs = {
  question: string;
  answer: string;
};

const SecurityQuestion = ({ onSuccess }: Props) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const [isFetching, setIsFetching] = useState(true);
  const [questionsData, setQuestionsData] = useState<any | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!questionsData) {
      setIsFetching(true);

      dispatch(getSecurityQuestionsAction()).then((res) => {
        setQuestionsData(res);
        setIsFetching(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitFormOnSubmit: SubmitHandler<FormInputs> = async (data) => {
    setIsSubmitting(true);

    await dispatch(
      updateSecurityQuestionAction({
        ...data,
      })
    ).then((res) => {
      if (res === true) {
        reset();
        onSuccess();
      }
    });
    setIsSubmitting(false);
  };

  const questions = questionsData?.map((x: any) => ({
    label: x?.question,
    value: x?.id,
  }));

  return (
    <div className="change_password_container">
      {isFetching ? (
        <Loader />
      ) : (
        <form className="form" onSubmit={handleSubmit(submitFormOnSubmit)}>
          <p>Set security question</p>
          <FormSelect
            name="question"
            label="SECURITY QUESTION"
            type="default"
            defaultValue=""
            errorMessage={errors?.question?.message}
            selectOptions={questions}
            selectRef={{
              ...register("question", basicFormValidation(true)),
            }}
          />
          <FormInput
            label="YOUR ANSWER"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.answer?.message}
            inputRef={{
              ...register("answer", textFormValidation(true, 1)),
            }}
          />

          <div className="action">
            <Button
              text="Save Changes"
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default SecurityQuestion;
