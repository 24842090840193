import React from "react";

import Pagination from "./pagination";

type Props = {
  isLoading: boolean;
  headers: string[];
  noRecord: boolean;
  children: React.ReactNode;
  pageCount?: number;
  changeData: (page: { selected: number }) => void;
};

const Table = ({
  isLoading,
  headers,
  noRecord,
  children,
  pageCount,
  changeData,
}: Props) => (
  <React.Fragment>
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            {headers.map((header, i) => (
              <th key={i}>{header}</th>
            ))}
          </tr>
        </thead>
        {isLoading ? (
          <tbody>
            <tr>
              <td colSpan={12} className="loading_text">
                Loading...
              </td>
            </tr>
          </tbody>
        ) : (
          <>
            {noRecord ? (
              <tbody>
                <tr>
                  <td colSpan={12} className="loading_text">
                    No record to show
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>{children}</tbody>
            )}
          </>
        )}
      </table>
    </div>
    {pageCount && <Pagination pageCount={pageCount} changeData={changeData} />}
  </React.Fragment>
);

export default Table;
