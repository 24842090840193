export const CheckmarkOrange = () => (
  <svg
    width="143"
    height="143"
    viewBox="0 0 143 143"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="71.5" cy="71.5" r="71.5" fill="#FF783A" />
    <circle cx="71.5" cy="71.5" r="71.5" fill="#FF783A" />
    <circle cx="71.5" cy="71.5" r="71.5" fill="#FF783A" />
    <circle cx="71.5" cy="71.5" r="71.5" fill="#FF783A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.6242 54.5427C97.8202 55.7387 97.8202 57.6778 96.6242 58.8738L68.0408 87.4571C66.8449 88.6531 64.9058 88.6531 63.7098 87.4571L47.3765 71.1238C46.1805 69.9278 46.1805 67.9887 47.3765 66.7927C48.5725 65.5968 50.5115 65.5968 51.7075 66.7927L65.8753 80.9606L92.2932 54.5427C93.4891 53.3468 95.4282 53.3468 96.6242 54.5427Z"
      fill="white"
    />
  </svg>
);
