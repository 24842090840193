export const Beneficiary = () => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20.4443" cy="20.2051" r="20" fill="#FF5001" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3131 14.3596C13.4638 13.0708 14.5558 12.099 15.8535 12.099H25.6196C25.7851 12.099 25.945 12.1592 26.0694 12.2685L28.579 14.4719C29.1431 14.9673 29.4548 15.69 29.4279 16.4403L29.1151 25.1433C29.0668 26.4882 27.9625 27.5535 26.6168 27.5535H15.6983C14.4896 27.5535 13.4691 26.6554 13.3156 25.4564C12.8643 21.9308 12.8448 18.3632 13.2577 14.8329L13.3131 14.3596ZM15.8535 13.4626C15.2477 13.4626 14.7378 13.9163 14.6675 14.518L14.6121 14.9913C14.2121 18.4115 14.2309 21.8677 14.6682 25.2833C14.7346 25.8016 15.1758 26.1899 15.6983 26.1899H16.2775V22.3263C16.2775 21.4476 16.9898 20.7354 17.8684 20.7354H24.2321C25.1107 20.7354 25.823 21.4476 25.823 22.3263V26.1899H26.6168C27.2285 26.1899 27.7304 25.7057 27.7524 25.0943L28.0651 16.3913C28.0774 16.0503 27.9357 15.7218 27.6792 15.4966L25.3684 13.4676V15.599C25.3684 16.4776 24.6561 17.1899 23.7775 17.1899H18.323C17.4443 17.1899 16.7321 16.4776 16.7321 15.599V13.4626H15.8535ZM18.0957 13.4626V15.599C18.0957 15.7245 18.1974 15.8262 18.323 15.8262H23.7775C23.903 15.8262 24.0048 15.7245 24.0048 15.599V13.4626H18.0957ZM24.4593 26.1899H17.6411V22.3263C17.6411 22.2008 17.7429 22.099 17.8684 22.099H24.2321C24.3576 22.099 24.4593 22.2008 24.4593 22.3263V26.1899Z"
      fill="white"
    />
  </svg>
);
