import { useState, useEffect } from "react";
import { useAppDispatch } from "../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from "../../components/button";
import FormInput from "../../components/form-input";
import Loader from "../../components/loader";

import {
  getNextOfKinAction,
  updateNextOfKinAction,
} from "../../redux/user/user-slice";

import { emailFormValidation, textFormValidation } from "../../utils/functions";

type FormInputs = {
  relationship: string;
  name: string;
  phone: string;
  address: string;
  email: string;
};

const NextOfKin = () => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const [isFetching, setIsFetching] = useState(false);
  const [nextOfKinInfo, setNextOfKinInfo] = useState<any | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!nextOfKinInfo) {
      setIsFetching(true);

      dispatch(getNextOfKinAction()).then((res) => {
        setNextOfKinInfo(res);
        setIsFetching(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nextOfKinInfo) {
      setValue("relationship", nextOfKinInfo?.relationship);
      setValue("name", nextOfKinInfo?.name);
      setValue("phone", nextOfKinInfo?.phone);
      setValue("address", nextOfKinInfo?.address);
      setValue("email", nextOfKinInfo?.email);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextOfKinInfo]);

  const submitFormOnSubmit: SubmitHandler<FormInputs> = async (data) => {
    setIsSubmitting(true);

    await dispatch(
      updateNextOfKinAction({
        ...data,
      })
    );
    setIsSubmitting(false);
  };

  return (
    <div className="residential_section">
      {isFetching ? (
        <Loader />
      ) : (
        <form className="form" onSubmit={handleSubmit(submitFormOnSubmit)}>
          <FormInput
            label="RELATIONSHIP"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.relationship?.message}
            inputRef={{
              ...register("relationship", textFormValidation(true, 1)),
            }}
          />
          <FormInput
            label="FULL NAME"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.name?.message}
            inputRef={{
              ...register("name", textFormValidation(true, 2)),
            }}
          />
          <FormInput
            label="PHONE NUMBER"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.phone?.message}
            inputRef={{
              ...register("phone", textFormValidation(true, 10)),
            }}
          />
          <FormInput
            label="ADDRESS"
            type="text"
            readOnly={isSubmitting}
            errorMessage={errors?.address?.message}
            inputRef={{
              ...register("address", textFormValidation(true, 2)),
            }}
          />
          <FormInput
            label="EMAIL"
            type="email"
            readOnly={isSubmitting}
            errorMessage={errors?.email?.message}
            inputRef={{
              ...register("email", emailFormValidation(true)),
            }}
          />

          <div className="action">
            <Button
              text="Save Changes"
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default NextOfKin;
