import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useAppDispatch } from "../redux";
import { toggleSidebar } from "../redux/components/components-slice";

import menuIcon from "../assets/svg/menu.svg";

import { getInitials } from "../utils/functions";

type Props = {
  titles?: string[];
  hideSidebarToggler?: boolean;
};

const TopBar = ({ titles, hideSidebarToggler }: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { user } = useSelector((state: any) => state.userSlice);

  return (
    <>
      <div className="topbar_container">
        {!hideSidebarToggler && window.innerWidth < 851 && (
          <div
            className="sidebar_toggler"
            onClick={() => dispatch(toggleSidebar(true))}
          >
            <img src={menuIcon} alt="" />
          </div>
        )}

        <h6 className="titles">
          {titles?.map((x, i) => (
            <span key={i}>{x}</span>
          ))}
        </h6>

        <div className="user" onClick={() => history.push("/profile")}>
          <div className="name">{user?.active_account?.account_name}</div>

          {user?.avatar_img ? (
            <img src={user?.avatar_img} alt="" />
          ) : (
            <div className="user_avatar">
              {getInitials(user?.active_account?.account_name)}
            </div>
          )}
        </div>
      </div>

      {user?.active_account &&
        user?.active_account?.account_status !== "Active" && (
          <div className="account_inactive">
            This account ({user?.active_account?.account_number}) is INACTIVE.
            Kindly contact support!
          </div>
        )}
    </>
  );
};

export default TopBar;
