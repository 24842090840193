import { useState } from "react";
import { useAppDispatch } from "../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from "../../components/button";
import FormInput from "../../components/form-input";

import { updatePinAction } from "../../redux/user/user-slice";

import { numberFormValidation } from "../../utils/functions";

type Props = {
  onSuccess: () => void;
};

type FormInputs = {
  old_pin: number;
  pin: number;
};

const ChangePin = ({ onSuccess }: Props) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitFormOnSubmit: SubmitHandler<FormInputs> = async (data) => {
    setIsSubmitting(true);

    await dispatch(
      updatePinAction({
        ...data,
        pin_confirmation: data?.pin,
      })
    ).then((res) => {
      if (res === true) {
        reset();
        onSuccess();
      }
    });
    setIsSubmitting(false);
  };

  return (
    <div className="change_password_container">
      <form className="form" onSubmit={handleSubmit(submitFormOnSubmit)}>
        <p>Change your pin to a new one</p>
        <FormInput
          label="CURRENT PIN"
          type="number"
          readOnly={isSubmitting}
          errorMessage={errors?.old_pin?.message}
          inputRef={{
            ...register("old_pin", numberFormValidation(true, 4, 4)),
          }}
        />
        <FormInput
          label="NEW PIN"
          type="number"
          readOnly={isSubmitting}
          errorMessage={errors?.pin?.message}
          inputRef={{
            ...register("pin", numberFormValidation(true, 4, 4)),
          }}
        />

        <div className="action">
          <Button
            text="Save Changes"
            type="submit"
            loading={isSubmitting}
            disabled={!isValid}
          />
        </div>
      </form>
    </div>
  );
};

export default ChangePin;
