export const Bank = () => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20.4443" cy="20.9852" r="20" fill="#FF5001" />
    <path
      d="M20.7996 11.4246L29.436 14.6973C29.7719 14.8246 30.0406 15.2064 30.0406 15.5428V18.561C30.0406 19.061 29.6087 19.4701 29.081 19.4701H11.8082C11.2805 19.4701 10.8486 19.061 10.8486 18.561V15.5428C10.8486 15.2064 11.1173 14.8246 11.4532 14.6973L20.0895 11.4246C20.2815 11.3519 20.6077 11.3519 20.7996 11.4246Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.0406 29.4701H10.8486V26.7428C10.8486 26.2428 11.2805 25.8337 11.8082 25.8337H29.081C29.6087 25.8337 30.0406 26.2428 30.0406 26.7428V29.4701Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7676 25.8337V19.4701"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.606 25.8337V19.4701"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.4443 25.8337V19.4701"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.2827 25.8337V19.4701"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.1211 25.8337V19.4701"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.88867 29.4701H30.9998"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.4443 17.1973C21.2392 17.1973 21.8837 16.5868 21.8837 15.8337C21.8837 15.0806 21.2392 14.4701 20.4443 14.4701C19.6493 14.4701 19.0049 15.0806 19.0049 15.8337C19.0049 16.5868 19.6493 17.1973 20.4443 17.1973Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
