import React, { useState } from "react";

import PageTitle from "../../components/page-title";
import TopBar from "../../components/top-bar";
import NavTabs from "../../components/navtabs";
import DoneModal from "../../components/modal/done-modal";

import ProfileInformation from "./profile-info";
import ResidentialAddress from "./residential";
// import MailingAddress from "./mailing";
// import EmailAndPhone from "./email-phone";
import NextOfKin from "./next-of-kin";
import Documents from "./documents";
import BVN from "./bvn";

const ProfilePage = () => {
  const [activeTab, setActiveTab] = useState("one");
  const [doneModal, setDoneModal] = useState(false);

  const switchTab = (id: string) => {
    setActiveTab(id);
  };

  let navigations = [
    {
      label: "Profile Information",
      id: "one",
      content: <ProfileInformation />,
    },
    {
      label: "Residential Address",
      id: "two",
      content: <ResidentialAddress />,
    },
    {
      label: "Documents",
      id: "three",
      content: <Documents />,
    },
    {
      label: "Next of Kin",
      id: "four",
      content: <NextOfKin />,
    },
    {
      label: "BVN",
      id: "five",
      content: <BVN />,
    },
    // {
    //   label: "Mailing Address",
    //   id: "three",
    //   content: <MailingAddress />,
    // },
    // {
    //   label: "Email and Phone Number",
    //   id: "four",
    //   content: <EmailAndPhone />,
    // },
  ];

  return (
    <React.Fragment>
      <PageTitle title="Profile" />
      <TopBar titles={["Profile"]} />

      <div className="profile_page_container">
        <NavTabs
          activeTab={activeTab}
          toggleTab={(id) => switchTab(id)}
          navItems={navigations}
          tabPanes={navigations}
        />
      </div>

      <DoneModal isOpen={doneModal} close={() => setDoneModal(false)} />
    </React.Fragment>
  );
};

export default ProfilePage;
