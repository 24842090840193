export const Loans = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.125 10.3125C7.125 11.04 7.68751 11.625 8.37751 11.625H9.78749C10.3875 11.625 10.875 11.115 10.875 10.4775C10.875 9.795 10.575 9.54751 10.1325 9.39001L7.875 8.6025C7.4325 8.445 7.13251 8.20501 7.13251 7.51501C7.13251 6.88501 7.61999 6.36751 8.21999 6.36751H9.63C10.32 6.36751 10.8825 6.95251 10.8825 7.68001"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 5.625V12.375"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 9C16.5 13.14 13.14 16.5 9 16.5C4.86 16.5 1.5 13.14 1.5 9C1.5 4.86 4.86 1.5 9 1.5"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.75 2.25V5.25H15.75"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 1.5L12.75 5.25"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
