import moment from "moment";

import Table from "../../components/table";

import DropDown from "../../components/dropdown";
import Button from "../../components/button";

import { formatAmount } from "../../utils/functions";
// import Icon from "../../assets/svg";

type Props = {
  params: {
    range: {
      label: string;
    };
  };
  setParams: any;
  isFetching: boolean;
  transactions: {}[];
};

const Transactions = ({
  params,
  setParams,
  isFetching,
  transactions,
}: Props) => {
  const tableHeaders = ["Transaction", "Date | Time", "Amount"];

  const filterOptions: {
    label: string;
    value: string;
  }[] = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 15 Days", value: "last15days" },
    { label: "Last 30 Days", value: "last30days" },
  ];

  return (
    <div className="table_container">
      <div className="table_filter">
        <div className="left">TRANSACTIONS</div>
        <div className="right">
          <DropDown
            toggler={<Button text={params.range?.label} rightIcon="chevron" />}
          >
            {filterOptions?.map((item, i) => (
              <div
                key={i}
                className="dropdown-item"
                onClick={() =>
                  setParams({
                    ...params,
                    range: item,
                  })
                }
              >
                {item?.label}
              </div>
            ))}
          </DropDown>
        </div>
      </div>
      <Table
        isLoading={isFetching}
        headers={tableHeaders}
        noRecord={transactions?.length < 1}
        pageCount={1}
        changeData={() => {}}
      >
        {transactions?.length < 1 ? (
          <tr>
            <td colSpan={12} className="loading_text">
              No record to show
            </td>
          </tr>
        ) : (
          transactions?.map((row: any, i: any) => (
            <tr key={i}>
              <td>
                <p className="text_wrap">{row?.name}</p>
              </td>
              <td>
                {moment(row?.last_updated_at).format("DD MMM, YYYY | hh:mm a")}
              </td>
              <td className={`${row?.type === "credit" ? "success" : ""}`}>
                {formatAmount(row?.amount, 2)}
              </td>
            </tr>
          ))
        )}
      </Table>
    </div>
  );
};

export default Transactions;
