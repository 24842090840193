import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../../redux";
import { useForm, SubmitHandler } from "react-hook-form";
import moment from "moment";

import FormInput from "../../../components/form-input";
import Button from "../../../components/button";

import {
  skipMoreInfoAction,
  updateMoreInfoAction,
} from "../../../redux/user/user-slice";

import { basicFormValidation } from "../../../utils/functions";
import DatePickerComponent from "../../../components/datepicker";

type Props = {
  onSubmitSuccess: any;
};

type FormInputs = {
  gender: string;
  selfieImage: File;
  idCardFront: File;
  idCardBack: File;
  signature: File;
};

const ABitMoreInfo = ({ onSubmitSuccess }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const skipMoreInfo = () => {
    dispatch(skipMoreInfoAction()).then((res: any) => {
      if (res === true) {
        onSubmitSuccess();
      }
      setIsSubmitting(false);
    });
  };

  const submitFormOnSubmit: SubmitHandler<FormInputs> = (data: any) => {
    setIsSubmitting(true);

    const payload = new FormData();
    payload.append("gender", data.gender);
    payload.append("date_of_birth", moment(selectedDate)?.format("DD-MM-YY"));
    payload.append("selfie_image", data.selfieImage[0]);
    payload.append("id_card_front", data.idCardFront[0]);
    payload.append("id_card_back", data.idCardBack[0]);
    payload.append("signature", data.signature[0]);

    dispatch(updateMoreInfoAction(payload)).then((res: any) => {
      if (res === true) {
        onSubmitSuccess();
      }
      setIsSubmitting(false);
    });
  };

  const datePickerDisplayValue = (date: Date) => {
    const moment = require("moment");
    return `${moment(date)?.format("DD MMM, yyyy")}`;
  };

  return (
    <React.Fragment>
      <form className="form" onSubmit={handleSubmit(submitFormOnSubmit)}>
        <div className="form_inner">
          <h5>A Bit More Information</h5>
          <h6>We want to know you even better</h6>
          <p className="skip_more_info" onClick={skipMoreInfo}>
            Skip this step
          </p>

          <div className="form_group_div">
            <label>SELECT GENDER</label>
            <div className="d_flex radio_selection_div">
              <FormInput
                label="Male"
                name="male"
                type="radio"
                id="g_male"
                value="male"
                inputRef={{
                  ...register("gender", basicFormValidation(true)),
                }}
                readOnly={isSubmitting}
                errorMessage={errors?.gender?.message}
              />
              <FormInput
                label="Female"
                name="female"
                type="radio"
                id="g_female"
                value="female"
                inputRef={{
                  ...register("gender", basicFormValidation(true)),
                }}
                readOnly={isSubmitting}
                errorMessage={errors?.gender?.message}
              />
            </div>
          </div>

          <div className="form_group_container">
            <div className="form-group">
              <label>DATE OF BIRTH</label>

              <DatePickerComponent
                onChange={(date: Date) => setSelectedDate(date)}
                value={datePickerDisplayValue(selectedDate)}
                range={false}
                showYearDropdown={true}
              />
            </div>
          </div>

          <p className="form-section-title">Identity Verification</p>

          <FormInput
            label="UPLOAD SELFIE IMAGE"
            type="file"
            readOnly={isSubmitting}
            errorMessage={errors?.selfieImage?.message}
            inputRef={{
              ...register("selfieImage", basicFormValidation(true)),
            }}
            accept="image/jpg,image/jpeg,image/png"
          />
          <FormInput
            label="UPLOAD ID CARD (FRONT)"
            type="file"
            readOnly={isSubmitting}
            errorMessage={errors?.idCardFront?.message}
            inputRef={{
              ...register("idCardFront", basicFormValidation(true)),
            }}
            accept="image/jpg,image/jpeg,image/png,application/pdf"
          />
          <FormInput
            label="UPLOAD ID CARD (BACK)"
            type="file"
            readOnly={isSubmitting}
            errorMessage={errors?.idCardBack?.message}
            inputRef={{
              ...register("idCardBack", basicFormValidation(true)),
            }}
            accept="image/jpg,image/jpeg,image/png,application/pdf"
          />
          <FormInput
            label="UPLOAD SIGNATURE"
            type="file"
            readOnly={isSubmitting}
            errorMessage={errors?.signature?.message}
            inputRef={{
              ...register("signature", basicFormValidation(true)),
            }}
            accept="image/jpg,image/jpeg,image/png,application/pdf"
          />

          <div className="actions">
            <Button
              text="Complete"
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
            />
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default ABitMoreInfo;
