import { useState } from "react";

import DropDown from "../../components/dropdown";
import Button from "../../components/button";

import { formatAmount } from "../../utils/functions";
import Icon from "../../assets/svg";

type Props = {
  params: {
    account: {
      label: string;
    };
  };
  setParams: any;
  accountList: {
    label: string;
    value: string;
  }[];
  activeAccount: any;
  refreshAccount: any;
  isRefreshing: boolean;
};

const AccountOverview = ({
  params,
  setParams,
  accountList,
  activeAccount,
  refreshAccount,
  isRefreshing,
}: Props) => {
  const [hideBalance, setHideBalance] = useState(false);

  return (
    <div className="account">
      <div className="d_flex">
        <DropDown
          toggler={<Button text={params.account?.label} rightIcon="chevron" />}
        >
          {accountList?.map((item, i) => (
            <div
              key={i}
              className="dropdown-item"
              onClick={() =>
                setParams({
                  ...params,
                  account: item,
                })
              }
            >
              {item?.label}
            </div>
          ))}
        </DropDown>

        <Button
          text="Refresh"
          leftIcon="refresh"
          className="btn_secondary"
          onClick={refreshAccount}
          loading={isRefreshing}
        />
      </div>

      <div>
        <p>Wallet Balance</p>
        <h6>
          {hideBalance
            ? "********"
            : formatAmount(activeAccount?.account_balance, 2)}
          <span onClick={() => setHideBalance(!hideBalance)}>
            <Icon name={hideBalance ? "eye" : "eyeSlash"} />
          </span>
        </h6>
      </div>
    </div>
  );
};

export default AccountOverview;
