import { useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";

import { sendOtpAction, forgotPinAction } from "../../redux/user/user-slice";

import EnterPin from "../../components/enter-pin";
import Button from "../../components/button";

type Props = {
  onSuccess: () => void;
};

const ForgotPin = ({ onSuccess }: Props) => {
  const dispatch = useAppDispatch();

  const { user } = useSelector((state: any) => state.userSlice);

  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState<string>("");
  const [pin, setPin] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendOtp = async () => {
    setIsSubmitting(true);

    await dispatch(sendOtpAction({ email: user?.email })).then((res) => {
      if (res === true) setStep(2);
    });

    setIsSubmitting(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    await dispatch(
      forgotPinAction({
        otp,
        pin,
        pin_confirmation: pin,
      })
    ).then((res) => {
      if (res === true) onSuccess();
    });
    setIsSubmitting(false);
  };

  return (
    <div className="change_password_container forgot_pin">
      <div className="form">
        {step === 1 ? (
          <>
            <h6>Request OTP</h6>
            <p>A 4 digit OTP will be sent to your email.</p>

            <div className="action">
              <Button text="Request" type="submit" onClick={sendOtp} />
            </div>
          </>
        ) : (
          <>
            <h6>Change PIN</h6>

            <br />

            <EnterPin
              showText={true}
              text="Enter the OTP sent to your email"
              pinLength={4}
              pin={otp}
              onChange={(value) => setOtp(value)}
              isDisabled={isSubmitting}
            />

            <br />

            <EnterPin
              showText={true}
              text="Enter your new PIN"
              pinLength={4}
              pin={pin}
              onChange={(value) => setPin(value)}
              isDisabled={isSubmitting}
            />

            <br />

            <div className="action">
              <Button
                text="Done"
                type="submit"
                onClick={handleSubmit}
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPin;
