export const Refresh = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.90913 4.12205C2.74772 4.07947 2.61356 3.96738 2.54293 3.81612C2.47231 3.66485 2.47253 3.49003 2.54352 3.33894L3.78909 0.688151C3.88191 0.490612 4.08062 0.364599 4.29888 0.364869C4.51714 0.365139 4.71554 0.491643 4.80788 0.689412L5.35735 1.86634C5.37699 1.85862 5.39727 1.85192 5.41815 1.84633C8.67866 0.972678 12.0301 2.90761 12.9037 6.16812C13.7774 9.42863 11.8424 12.78 8.58192 13.6537C5.32141 14.5273 1.97001 12.5924 1.09636 9.33189C0.818538 8.29507 0.824788 7.2474 1.06811 6.27335C1.1434 5.97195 1.44877 5.78866 1.75017 5.86395C2.05157 5.93924 2.23486 6.24461 2.15957 6.54601C1.96135 7.3395 1.95589 8.19305 2.18302 9.04072C2.89586 11.7011 5.63039 13.2799 8.29075 12.567C10.9511 11.8542 12.5299 9.11965 11.817 6.45929C11.1159 3.84243 8.45856 2.27207 5.83986 2.89986L6.39426 4.08734C6.48659 4.2851 6.45618 4.51843 6.31625 4.68593C6.17631 4.85343 5.95212 4.92486 5.74108 4.86918L2.90913 4.12205Z"
      fill="black"
    />
  </svg>
);
