export const Cards = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 9.45749H14.25"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.25 7.71001V13.0725C14.2275 15.21 13.6425 15.75 11.415 15.75H4.33502C2.07002 15.75 1.5 15.1875 1.5 12.9525V7.71001C1.5 5.68501 1.9725 5.03251 3.75 4.92751C3.93 4.92001 4.12502 4.91251 4.33502 4.91251H11.415C13.68 4.91251 14.25 5.47501 14.25 7.71001Z"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 5.0475V10.29C16.5 12.315 16.0275 12.9675 14.25 13.0725V7.71C14.25 5.475 13.68 4.9125 11.415 4.9125H4.33502C4.12502 4.9125 3.93 4.92 3.75 4.9275C3.7725 2.79 4.35752 2.25 6.58502 2.25H13.665C15.93 2.25 16.5 2.8125 16.5 5.0475Z"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.9375 13.3575H5.22748"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.83252 13.3575H9.41252"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
