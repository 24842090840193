import DatePicker from "react-datepicker";

import Icon from "../assets/svg";

type Props = {
  startDate?: any;
  endDate?: any;
  onChange: any;
  value?: any;
  range: boolean;
  showYearDropdown?: boolean;
  placeholderText?: string;
  maxDate?: any;
  minDate?: any;
};

const DatePickerComponent = ({
  startDate,
  endDate,
  onChange,
  value,
  range,
  showYearDropdown,
  placeholderText,
  maxDate,
  minDate,
  ...rest
}: Props) => {
  return (
    <div className="datepicker_container">
      <Icon name="calendar" />
      <DatePicker
        startDate={startDate}
        endDate={endDate}
        dateFormat="yyyy-MM-dd"
        className="date_picker_input"
        shouldCloseOnSelect={false}
        maxDate={maxDate ? maxDate : new Date()}
        selectsRange={range ? true : false}
        onChange={onChange}
        value={value}
        showYearDropdown={showYearDropdown ? true : false}
        peekNextMonth={showYearDropdown ? true : false}
        showMonthDropdown={showYearDropdown ? true : false}
        dropdownMode="select"
        placeholderText={placeholderText}
        {...rest}
      />
    </div>
  );
};

export default DatePickerComponent;
