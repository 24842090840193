export const Accounts = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.475 5.93999V9.80251C14.475 12.1125 13.155 13.1025 11.175 13.1025H4.58247C4.24497 13.1025 3.92247 13.0725 3.62247 13.005C3.43497 12.975 3.25497 12.9225 3.08997 12.8625C1.96497 12.4425 1.28247 11.4675 1.28247 9.80251V5.93999C1.28247 3.62999 2.60247 2.64001 4.58247 2.64001H11.175C12.855 2.64001 14.0625 3.35251 14.385 4.98001C14.4375 5.28001 14.475 5.58749 14.475 5.93999Z"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.7258 8.19008V12.0526C16.7258 14.3626 15.4058 15.3526 13.4258 15.3526H6.83329C6.27829 15.3526 5.7758 15.2776 5.3408 15.1126C4.4483 14.7826 3.84079 14.1001 3.62329 13.0051C3.92329 13.0726 4.24579 13.1026 4.58329 13.1026H11.1758C13.1558 13.1026 14.4758 12.1126 14.4758 9.8026V5.94008C14.4758 5.58758 14.4458 5.2726 14.3858 4.9801C15.8108 5.2801 16.7258 6.28508 16.7258 8.19008Z"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.87381 9.85492C8.96733 9.85492 9.85382 8.96843 9.85382 7.87491C9.85382 6.78138 8.96733 5.8949 7.87381 5.8949C6.78029 5.8949 5.8938 6.78138 5.8938 7.87491C5.8938 8.96843 6.78029 9.85492 7.87381 9.85492Z"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.58496 6.22499V9.52501"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1663 6.22522V9.52524"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
