export const Savings = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.06369 12.6453V14.1678C8.06369 15.4578 6.86368 16.5003 5.38618 16.5003C3.90868 16.5003 2.70117 15.4578 2.70117 14.1678V12.6453C2.70117 13.9353 3.90118 14.8503 5.38618 14.8503C6.86368 14.8503 8.06369 13.9278 8.06369 12.6453Z"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.06248 10.5825C8.06248 10.9575 7.95748 11.3025 7.77748 11.6025C7.33498 12.33 6.42747 12.7875 5.37747 12.7875C4.32747 12.7875 3.41996 12.3225 2.97746 11.6025C2.79746 11.3025 2.6925 10.9575 2.6925 10.5825C2.6925 9.93749 2.99249 9.35999 3.47249 8.93999C3.95999 8.51249 4.62746 8.25751 5.36996 8.25751C6.11246 8.25751 6.77999 8.51999 7.26749 8.93999C7.76249 9.35249 8.06248 9.93749 8.06248 10.5825Z"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.06369 10.5825V12.645C8.06369 13.935 6.86368 14.85 5.38618 14.85C3.90868 14.85 2.70117 13.9275 2.70117 12.645V10.5825C2.70117 9.29249 3.90118 8.25 5.38618 8.25C6.12868 8.25 6.79621 8.51248 7.28371 8.93248C7.76371 9.35248 8.06369 9.93749 8.06369 10.5825Z"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 8.22748V9.77252C16.5 10.185 16.17 10.5225 15.75 10.5375H14.28C13.47 10.5375 12.7275 9.94499 12.66 9.13499C12.615 8.66249 12.795 8.21999 13.11 7.91249C13.3875 7.62749 13.77 7.46251 14.19 7.46251H15.75C16.17 7.47751 16.5 7.81498 16.5 8.22748Z"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 7.875V6.375C1.5 4.335 2.73 2.91 4.6425 2.67C4.8375 2.64 5.04 2.625 5.25 2.625H12C12.195 2.625 12.3825 2.63249 12.5625 2.66249C14.4975 2.88749 15.75 4.32 15.75 6.375V7.46251H14.19C13.77 7.46251 13.3875 7.62749 13.11 7.91249C12.795 8.21999 12.615 8.66249 12.66 9.13499C12.7275 9.94499 13.47 10.5375 14.28 10.5375H15.75V11.625C15.75 13.875 14.25 15.375 12 15.375H10.125"
      stroke="#4E4E4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
