import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import FormInput from "../../../components/form-input";
import Button from "../../../components/button";
import ConfirmationModal from "../../../components/modal/confirmation-modal";

import { confirmBvnAction } from "../../../redux/user/user-slice";

import { numberFormValidation } from "../../../utils/functions";

type Props = {
  funcToUse?: any;
  cancelSession?: any;
  onSubmitSuccess: any;
};

type FormInputs = {
  otp: number;
};

const OtpSection = ({ funcToUse, cancelSession, onSubmitSuccess }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();

  const { user } = useSelector((state: any) => state.userSlice);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isNoBvnvModalOpen, setIsNoBvnvModalOpen] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const submitFormOnSubmit: SubmitHandler<FormInputs> = (data) => {
    setIsSubmitting(true);

    const func = funcToUse ? funcToUse : confirmBvnAction;

    const payload = {
      bvn: user?.bvn,
      otp: data.otp,
    };
    funcToUse && delete payload.bvn;

    dispatch(func(payload)).then((res: any) => {
      if (res === true) {
        onSubmitSuccess();
      }
      setIsSubmitting(false);
    });
  };

  return (
    <React.Fragment>
      <form
        className="form bvn_form"
        onSubmit={handleSubmit(submitFormOnSubmit)}
      >
        <div className="form_inner">
          <h5>OTP Verification</h5>
          <h6>Enter the one-time password (OTP) sent to {user?.phone}</h6>

          <FormInput
            label="OTP"
            type="number"
            readOnly={isSubmitting}
            errorMessage={errors?.otp?.message}
            inputRef={{
              ...register("otp", numberFormValidation(true, 4, 4)),
            }}
          />

          <div className="actions">
            <Button
              text="Continue"
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
            />

            <div className="didnt_get_otp">
              {/* <p>Didn't receive the OTP?</p>
              <div className="btn_text" onClick={() => {}}>
                Resend
              </div> */}
            </div>

            <p
              className="btn_cancel"
              onClick={() =>
                cancelSession ? cancelSession() : setIsNoBvnvModalOpen(true)
              }
            >
              Cancel
            </p>
          </div>
        </div>
      </form>

      {isNoBvnvModalOpen && (
        <ConfirmationModal
          isOpen={isNoBvnvModalOpen}
          close={() => setIsNoBvnvModalOpen(false)}
          onClick={() => setIsNoBvnvModalOpen(false)}
          isSubmitting={isSubmitting}
          headText="Cancel Account Creation"
          text={`By canceling, you will loose the progress
          you’ve made so far on creating an account.`}
        />
      )}
    </React.Fragment>
  );
};

export default OtpSection;
