import React, { useState } from "react";

import PageTitle from "../../components/page-title";
import TopBar from "../../components/top-bar";
import NavTabs from "../../components/navtabs";
import DoneModal from "../../components/modal/done-modal";

import ChangePassword from "./change-password";
import ChangePin from "./change-pin";
import ForgotPin from "./forgot-pin";
import SecurityQuestion from "./security-question";

const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState("one");
  const [doneModal, setDoneModal] = useState(false);

  const switchTab = (id: string) => {
    setActiveTab(id);
  };

  let navigations = [
    {
      label: "Change Password",
      id: "one",
      content: <ChangePassword onSuccess={() => setDoneModal(true)} />,
    },
    {
      label: "Change PIN",
      id: "two",
      content: <ChangePin onSuccess={() => setDoneModal(true)} />,
    },
    {
      label: "Forgot PIN",
      id: "three",
      content: <ForgotPin onSuccess={() => setDoneModal(true)} />,
    },
    {
      label: "Security Question",
      id: "four",
      content: <SecurityQuestion onSuccess={() => setDoneModal(true)} />,
    },
  ];

  return (
    <React.Fragment>
      <PageTitle title="Settings" />
      <TopBar titles={["Settings"]} />

      <div className="settings_page_container">
        <NavTabs
          activeTab={activeTab}
          toggleTab={(id) => switchTab(id)}
          navItems={navigations}
          tabPanes={navigations}
        />
      </div>

      <DoneModal isOpen={doneModal} close={() => setDoneModal(false)} />
    </React.Fragment>
  );
};

export default SettingsPage;
