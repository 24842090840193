import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../../redux";
import { useForm, SubmitHandler } from "react-hook-form";

import FormInput from "../../../components/form-input";
import Button from "../../../components/button";
import ConfirmationModal from "../../../components/modal/confirmation-modal";

import Icon from "../../../assets/svg";

import { validateBvnAction } from "../../../redux/user/user-slice";

import { numberFormValidation } from "../../../utils/functions";

type Props = {
  defaultBvn?: string;
  hideNoBvnBtn?: boolean;
  onSubmitSuccess: any;
  noBnvConfirmation?: any;
};

type FormInputs = {
  bvn: number;
};

const BvnSection = ({
  defaultBvn,
  hideNoBvnBtn,
  onSubmitSuccess,
  noBnvConfirmation,
}: Props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
  });
  const dispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isNoBvnvModalOpen, setIsNoBvnvModalOpen] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (defaultBvn) {
      setValue("bvn", parseInt(defaultBvn));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultBvn]);

  const submitFormOnSubmit: SubmitHandler<FormInputs> = (data) => {
    setIsSubmitting(true);

    dispatch(
      validateBvnAction({
        bvn: data.bvn,
      })
    ).then((res: any) => {
      if (res === true) {
        onSubmitSuccess();
      }
      setIsSubmitting(false);
    });
  };

  return (
    <React.Fragment>
      <form
        className="form bvn_form"
        onSubmit={handleSubmit(submitFormOnSubmit)}
      >
        <div className="form_inner">
          <h5>BVN</h5>
          <h6>We request for your BVN to confirm who you are</h6>

          <FormInput
            label="BVN"
            type="number"
            readOnly={isSubmitting}
            errorMessage={errors?.bvn?.message}
            inputRef={{
              ...register("bvn", numberFormValidation(true, 11, 11)),
            }}
          />

          <div className="bnv_info">
            <Icon name="question" />

            <p>We will send a code to your phone number linked to your BVN.</p>
          </div>

          <div className="actions">
            <Button
              text="Continue"
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
            />

            {!hideNoBvnBtn && (
              <>
                <div className="or"></div>

                <Button
                  text="Don't have BVN?"
                  className="btn_secondary"
                  onClick={(e: any) => {
                    e.preventDefault();

                    setIsNoBvnvModalOpen(true);
                  }}
                />
              </>
            )}
          </div>
        </div>
      </form>

      {isNoBvnvModalOpen && (
        <ConfirmationModal
          isOpen={isNoBvnvModalOpen}
          close={() => setIsNoBvnvModalOpen(false)}
          onClick={() => {
            noBnvConfirmation();
            setIsNoBvnvModalOpen(false);
          }}
          isSubmitting={isSubmitting}
          text={
            <>
              If you don’t have a BVN we will create a wallet for you.
              <br />
              <br />
              You can upgrade your account later.
            </>
          }
        />
      )}
    </React.Fragment>
  );
};

export default BvnSection;
