import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import Icon from "../assets/svg";

type Props = {
  toggler: React.ReactNode;
  icon?: React.ReactNode;
  children: React.ReactNode;
};

const DropDown = ({ toggler, icon, children }: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className="dropdown_container"
    >
      <DropdownToggle className="drop">
        {toggler}
        {icon && (
          <span className="chevron">
            <Icon name="chevron" />
          </span>
        )}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu" onClick={toggle}>
        {children}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropDown;
