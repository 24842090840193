import Icon from "../assets/svg";

type Props = {
  headText: string;
  headTextColored?: boolean;
  close: () => void;
  subText?: string;
};

const SectionHeader = ({
  headText,
  headTextColored,
  close,
  subText,
}: Props) => {
  return (
    <div className="section_header_container">
      <div className="head_text_div">
        <h6 className={`${headTextColored ? "colored" : ""}`}>{headText}</h6>
        {close && (
          <div className="close" onClick={close}>
            <Icon name="close" />
          </div>
        )}
      </div>
      {subText && <p>{subText}</p>}
    </div>
  );
};

export default SectionHeader;
