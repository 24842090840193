type Props = {
  id?: string;
  name?: string;
  label?: string;
  type: string;
  placeholder?: string;
  accept?: string;
  value?: string;
  inputRef?: any;
  readOnly: boolean;
  errorMessage: any;
};

const FormInput = ({
  id,
  name,
  label,
  type,
  placeholder,
  accept,
  value,
  inputRef,
  readOnly,
  errorMessage,
  ...rest
}: Props) => {
  const checkBoxInputTypes = ["checkbox", "radio"];

  return (
    <div className="form_group_container">
      {!checkBoxInputTypes.includes(type) ? (
        <div className="form-group">
          {label && <label>{label}</label>}
          <input
            name={name}
            type={type}
            placeholder={placeholder}
            accept={accept}
            // defaultValue={value}
            value={value}
            className="form-control"
            readOnly={readOnly ? true : false}
            {...inputRef}
            {...rest}
          />
        </div>
      ) : (
        <div className="form-group form-check">
          <input
            className="form-check-input"
            name={name}
            type={type}
            defaultValue={value}
            id={id}
            readOnly={readOnly ? true : false}
            {...inputRef}
            {...rest}
          />
          <label className="form-check-label" htmlFor={id}>
            {label}
          </label>
        </div>
      )}
      {errorMessage && <span>* {errorMessage}</span>}
    </div>
  );
};

export default FormInput;
