import { createSlice } from "@reduxjs/toolkit";

import { postRequest, getRequest } from "../../api";
import { API_URLS } from "../../api";

import { setAlert } from "../components/components-slice";

import { getRequestError, useSessionStorage } from "../../utils/functions";
import {
  APP_BANKS,
  APP_BENEFICIARIES,
  APP_BILL_BENEFICIARIES,
  APP_NETWORK_PROVIDERS,
  APP_BILL_PROVIDERS,
} from "../../utils/constants";

type Props = {
  transactions: [] | any;
  banks: [] | any;
  beneficiaries: [] | any;
  billBeneficiaries: [] | any;
  billProviders: [] | any;
  networkProviders: [] | any;
};
export const initialState: Props = {
  transactions: [],
  banks: useSessionStorage.get(APP_BANKS),
  beneficiaries: useSessionStorage.get(APP_BENEFICIARIES),
  billBeneficiaries: useSessionStorage.get(APP_BILL_BENEFICIARIES),
  billProviders: useSessionStorage.get(APP_BILL_PROVIDERS),
  networkProviders: useSessionStorage.get(APP_NETWORK_PROVIDERS),
};

// Slice
const slice = createSlice({
  name: "home",
  initialState,
  reducers: {
    getAccountsSuccess: (state, { payload }) => {
      state.transactions = payload;
    },
    getBanksSuccess: (state, { payload }) => {
      state.banks = payload;
      useSessionStorage.set(APP_BANKS, payload);
    },
    getBeneficiariesSuccess: (state, { payload }) => {
      state.beneficiaries = payload;
      useSessionStorage.set(APP_BENEFICIARIES, payload);
    },
    getBillBeneficiariesSuccess: (state, { payload }) => {
      state.billBeneficiaries = payload;
      useSessionStorage.set(APP_BILL_BENEFICIARIES, payload);
    },
    getBillProvidersSuccess: (state, { payload }) => {
      state.billProviders = payload;
      useSessionStorage.set(APP_BILL_PROVIDERS, payload);
    },
    getNetworkProvidersSuccess: (state, { payload }) => {
      state.networkProviders = payload;
      useSessionStorage.set(APP_NETWORK_PROVIDERS, payload);
    },
  },
});
export default slice.reducer;

// Actions
const {
  getAccountsSuccess,
  getBanksSuccess,
  getBeneficiariesSuccess,
  getBillBeneficiariesSuccess,
  getBillProvidersSuccess,
  getNetworkProvidersSuccess,
} = slice.actions;

export const getTransactionsByAccountAction =
  (data: any) => async (dispatch: any) => {
    try {
      const res = await getRequest({
        url: API_URLS?.transactionsByAccount,
        params: data,
      });
      dispatch(getAccountsSuccess(res.data?.data));
      return res.data?.data;
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const getBanksAction = () => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: API_URLS?.banks,
      params: null,
    });
    dispatch(getBanksSuccess(res.data?.data));
    return res.data?.data;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const getBeneficiariesAction = () => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: API_URLS?.beneficiaries,
      params: null,
    });
    dispatch(getBeneficiariesSuccess(res.data?.data));
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const getBillBeneficiariesAction = () => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: API_URLS?.billBeneficiaries,
      params: null,
    });
    dispatch(getBillBeneficiariesSuccess(res.data?.data));
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const validateInterBankAccountAction =
  (data: any) => async (dispatch: any) => {
    try {
      const res = await postRequest({
        url: API_URLS?.validateInterBankAccount,
        data,
        params: null,
        formData: null,
      });

      return { status: res.data?.status, name: res.data?.data?.name };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const validateIntraBankAccountAction =
  (data: any) => async (dispatch: any) => {
    try {
      const res = await postRequest({
        url: API_URLS?.validateIntraBankAccount,
        data,
        params: null,
        formData: null,
      });

      return { status: res.data?.status, name: res.data?.data?.name };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const postInterBankTransferAction =
  (data: any) => async (dispatch: any) => {
    try {
      const res = await postRequest({
        url: API_URLS?.interBankTransfer,
        data,
        params: null,
        formData: null,
      });

      return res.data?.status;
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const postIntraBankTransferAction =
  (data: any) => async (dispatch: any) => {
    try {
      const res = await postRequest({
        url: API_URLS?.intraBankTransfer,
        data,
        params: null,
        formData: null,
      });

      return res.data?.status;
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const getBillProvidersAction = () => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: API_URLS?.billProviders,
      params: null,
    });
    dispatch(getBillProvidersSuccess(res.data?.data));
    return res.data?.data;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const postBillPaymentAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await postRequest({
      url: API_URLS?.billPayment,
      data,
      params: null,
      formData: null,
    });

    return res.data?.status;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const getNetworkProvidersAction = () => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: API_URLS?.networkProviders,
      params: null,
    });
    dispatch(getNetworkProvidersSuccess(res.data?.data));
    return res.data?.data;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const getDataPlansAction =
  (data: { provider: string }) => async (dispatch: any) => {
    try {
      const res = await getRequest({
        url: API_URLS?.dataPlans,
        params: data,
      });
      return res.data?.data;
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const postAirtimePurchaseAction =
  (data: any) => async (dispatch: any) => {
    try {
      const res = await postRequest({
        url: API_URLS?.airtimePurchase,
        data,
        params: null,
        formData: null,
      });

      return res.data?.status;
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const postDataPurchaseAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await postRequest({
      url: API_URLS?.dataPurchase,
      data,
      params: null,
      formData: null,
    });

    return res.data?.status;
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};
